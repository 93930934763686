import { useMemo } from "react";
import SponsorSlider from "@health/SponsorSlider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { Typography, TypographyFormat } from "eva-lib";

import { SponsorSectionContentProps } from "./SponsorSectionContent.interface";

function SponsorSectionContent({
  sponsorInfo,
  hideCarousel,
  hideDescription,
  className,
}: SponsorSectionContentProps) {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("tw-md"));

  const customDivider = useMemo(() => {
    return (
      <TypographyFormat
        variant="h4"
        content={sponsorInfo.title.text}
        className={{
          root: clsx("mx-s font-semibold text-neutral-900", {
            "text-center": matchSm,
          }),
        }}
      />
    );
  }, [matchSm]);

  return (
    <div
      className={clsx(
        "relative grid grid-cols-12 gap-y-0 gap-x-s-l",
        className?.root
      )}
    >
      <div className="col-span-12 mb-l">{customDivider}</div>
      {!hideCarousel && (
        <div
          className={clsx("col-span-12 self-center", {
            "md:col-span-10": !hideDescription,
          })}
        >
          <SponsorSlider
            slidesToShowDesk={sponsorInfo.slidesToShowDesk}
            slidesToShowMobil={sponsorInfo.slidesToShowMobil}
            sponsorImagList={sponsorInfo.sponsorImagList}
          />
        </div>
      )}
      {!hideDescription && (
        <div className={clsx("hidden md:col-span-2 md:block")}>
          <Typography variant="h5" className={{ root: className?.description }}>
            {sponsorInfo.bodyText}
          </Typography>
        </div>
      )}
    </div>
  );
}
export default SponsorSectionContent;
