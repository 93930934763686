import { useEffect, useState } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

/**
 * slider to show the list of sponsors within the home page
 * @param props.slidesToShowDesk number of images to show in the
 * slider in desktop format
 * @param props.slidesToShowMobil number of images to show in the
 * slider in mobil format
 * @param props.sponsorImagList list of images to be shown inside the slider
 * @param props.sponsorProps Generic props
 * @returns
 */
function SponsorSlider(props) {
  const location = process.env.PUBLIC_URL;

  const {
    slidesToShowDesk,
    slidesToShowMobil,
    sponsorImagList,
    ...sponsorProps
  } = props;

  const [slidesToShowCustom, setSlidesToShowCustom] = useState(
    getSlidesToShowCustom()
  );

  useEffect(() => {
    function handleResize() {
      setSlidesToShowCustom(getSlidesToShowCustom());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getSlidesToShowCustom() {
    const { innerWidth: width } = window;
    if (width >= 960) {
      return slidesToShowDesk;
    }
    return slidesToShowMobil;
  }

  const carouselProps = {
    slidesPerView: slidesToShowCustom,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    modules: [Autoplay],
    loop: true,
  };

  return (
    <div {...sponsorProps}>
      <Swiper {...carouselProps}>
        {sponsorImagList.map(({ id, src, alt }) => (
          <SwiperSlide key={id}>
            <div className=" h-full px-s-l text-center">
              <img
                width="100%"
                key={id}
                src={location + src}
                alt={alt}
                className="h-l-xl align-middle"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SponsorSlider;
