import { memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { homeRoute, publicUrl } from "app/routes";
import { CheckDataForRoute } from "app/utils/";

function ProtectRoute({ children }) {
  const location = useLocation();
  let { pathname } = location;
  if (publicUrl) pathname = pathname.replace(publicUrl, "");

  pathname = pathname.substring(1);

  const isValidRoute = CheckDataForRoute(pathname);

  if (!isValidRoute) {
    return <Navigate to={homeRoute} state={{ from: location }} />;
  }
  return children;
}

export default memo(ProtectRoute);
