import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dependents: [],
};

export const dependentsSlice = createSlice({
  name: "subscription/dependents",
  initialState,
  reducers: {
    addDependent: (state, action) => {
      state.dependents.push(action.payload);
    },
    setDependents: (state, action) => {
      state.dependents = action.payload;
    },
    updateDependent: (state, action) => {
      state.dependents[action.payload.index] = action.payload.update;
    },
    updateDependentById: (state, action) => {
      const dependentIndex = state.dependents.findIndex(
        (item) => item.identification === action.payload.identification
      );
      state.dependents[dependentIndex] = action.payload;
    },
    removeDependent: (state, action) => {
      const update = state.dependents.filter(
        (item) => item.identification !== action.payload
      );
      state.dependents = update;
    },
    addDependentPreExisting: (state, action) => {
      const dependentIndex = state.dependents.findIndex(
        (item) => item.name === action.payload.name
      );
      state.dependents[dependentIndex].preExisting.push(
        action.payload.preExisting
      );
    },
    setDependentAllPreExistingList: (state, action) => {
      const dependentIndex = state.dependents.findIndex(
        (item) => item.identification === action.payload.identification
      );
      state.dependents[dependentIndex].allPreExistingList =
        action.payload.allPreExistingList;
      state.dependents[dependentIndex].loadPreExisting = true;
    },
    removeDependentPreExisting: (state, action) => {
      const dependentIndex = state.dependents.findIndex(
        (item) => item.identification === action.payload.identification
      );
      const update = state.dependents[dependentIndex].preExisting.filter(
        (item) => item.cieCode !== action.payload.cieCode
      );
      state.dependents[dependentIndex].preExisting = update;
    },
    removeAllDependentPreExisting: (state, action) => {
      const dependentIndex = state.dependents.findIndex(
        (item) => item.identification === action.payload.identification
      );
      state.dependents[dependentIndex].preExisting = [];
    },
    replaceAllDependentPreExisting: (state, action) => {
      const dependentIndex = state.dependents.findIndex(
        (item) => item.identification === action.payload.identification
      );
      state.dependents[dependentIndex].preExisting = action.payload.preExisting;
    },
  },
});
export const saveDependent = (payload) => (dispatch, getState) => {
  const { dependents } = getState().dependentsInfo;
  let indexDependent = -1;
  if (dependents.length > 0) {
    indexDependent = dependents.findIndex((item) => item.name === payload.name);
  }
  // init preExisting empty
  if (!payload.preExisting) {
    payload.preExisting = [];
  }

  if (indexDependent === -1) {
    dispatch(dependentsSlice.actions.addDependent(payload));
  } else {
    dispatch(
      dependentsSlice.actions.updateDependent({
        update: payload,
        index: indexDependent,
      })
    );
  }
};

export const {
  addDependent,
  updateDependentById,
  removeDependent,
  addDependentPreExisting,
  setDependentAllPreExistingList,
  setDependents,
  removeDependentPreExisting,
  removeAllDependentPreExisting,
  replaceAllDependentPreExisting,
} = dependentsSlice.actions;

export default dependentsSlice.reducer;
