import { useState } from "react";
import ProgressBar from "@health/ProgressBar";
import Spinner from "@health/Spinner";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { hasData } from "app/utils";
import clsx from "clsx";
import { Typography } from "eva-lib";
import colors from "styles/config-colors";

import { LoadingDialogProps } from "./LoadingDialog.interface";

function LoadingDialog(props: LoadingDialogProps) {
  const waitingTime = require("app/assets/exceptions/waitingTime.webm");
  const timerVersion = !!hasData(props.progresTimer);
  const [currentProgress, setCurrentProgress] = useState(0);
  const oneSecond = 1000;
  const increment = hasData(props.progresTimer)
    ? Math.round(100 / (props.progresTimer / oneSecond))
    : 0;

  const updateProgrees = () => {
    const progress = currentProgress + increment;
    if (progress <= 100) {
      setCurrentProgress(progress);
    }
  };

  if (props.open && hasData(props.progresTimer) && currentProgress < 100) {
    setTimeout(updateProgrees, oneSecond);
  }

  const dialogProps = {
    fullWidth: true,
    open: props.open,
    fullScreen: props.fullScreen,
    disableEscapeKeyDown: true,
    PaperProps: {
      elevation: 0,
      className: "alert-modal-paper",
      style: { backgroundColor: "transparent" },
    },
    BackdropProps: {
      style: {
        backgroundColor: colors.white,
        opacity: 0.9,
      },
    },
  };

  /* eslint-disable jsx-a11y/media-has-caption */
  const animationProgress = (
    <>
      <video src={waitingTime} autoPlay loop className="m-auto" />
      <ProgressBar
        value={currentProgress}
        className={{ linear: "!h-8 w-full" }}
      />
    </>
  );
  const circularProgress = (
    <Spinner
      size={70}
      thickness={4}
      className="spinner-primary-color left-1/2"
    />
  );

  return (
    <Dialog {...dialogProps} maxWidth="xs" scroll="paper">
      <DialogContent
        style={{ alignSelf: "center" }}
        className={clsx(timerVersion && "flex w-2/3 p-0 ")}
      >
        <div className={clsx(timerVersion && "w-full")}>
          {timerVersion ? animationProgress : circularProgress}
        </div>
      </DialogContent>
      {props.title !== undefined || props.subtitle !== undefined ? (
        <Box className="mt-8 text-center">
          <Typography
            variant={timerVersion ? "h5" : "h4"}
            className={{
              root: clsx(
                "mb-xs",
                timerVersion && "font-normal text-primary-alternative3-400"
              ),
            }}
          >
            {props.title}
          </Typography>
          <Typography variant={timerVersion ? "body1" : "body2"}>
            {props.subtitle}
          </Typography>
        </Box>
      ) : (
        <> </>
      )}
    </Dialog>
  );
}

export default LoadingDialog;
