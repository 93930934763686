import React from "react";
import EvaButton from "@eva/EvaButton";
import clsx from "clsx";

import { DualButtonProps } from "./DualButton.interface";

const DualButton = (props: DualButtonProps) => {
  const { primaryButton, secondaryButton, className, active } = props;

  const isPrimaryActive = active === "primary";

  return (
    <div className={clsx("mx-s-l flex lg:mx-auto", className?.root)}>
      <EvaButton
        className={{
          root: clsx(
            "body2 mr-[-15px] max-h-l w-full",
            !isPrimaryActive && "border-neutral-300 bg-transparent text-black",
            isPrimaryActive && "z-10 my-[1px]",
            className?.primaryButton
          ),
        }}
        type="submit"
        variant={isPrimaryActive ? "contained" : "outlined"}
        shape="rounded"
        onClick={primaryButton.onClick}
        data-testid="button-confiRed1"
      >
        {primaryButton.label}
      </EvaButton>
      <EvaButton
        className={{
          root: clsx(
            "body2 ml-[-15px] max-h-l w-full ",
            isPrimaryActive && "border-neutral-300 bg-transparent text-black",
            !isPrimaryActive && "z-10 my-[1px]",
            className?.secondaryButton
          ),
        }}
        type="submit"
        variant={!isPrimaryActive ? "contained" : "outlined"}
        shape="rounded"
        onClick={secondaryButton.onClick}
        data-testid="button-confiRed2"
      >
        {secondaryButton.label}
      </EvaButton>
    </div>
  );
};

export default DualButton;
