import configuracion from "app/data/configuration/configuration.json";
import { createServiceGroup } from "app/utils/Service";

const timeoutQuotation = configuracion.services.apiTimeout.quotation;

type QuotationServices =
  | "getProductByConfiguredFiltersCode"
  | "getCatalogsByCodes"
  | "getCountries"
  | "postValidateId"
  | "postInfo"
  | "sendQuotationEmailByData"
  | "getPlansForQuotation"
  | "createQuotationByTrackingId"
  | "closeQuotation"
  | "updateQuotationByTrackingId"
  | "registerLopdp"
  | "validateLopdp"
  | "getManyInputsByCode";

export default createServiceGroup<QuotationServices>({
  services: {
    getProductByConfiguredFiltersCode: {
      path: "quotation/product/by-advanced-filters-codes",
    },
    getCatalogsByCodes: {
      path: "quotation/configuration/many-catalogs-by-codes",
    },
    getCountries: {
      path: "/integration/confiamed/countries",
    },
    postValidateId: {
      path: "quotation/validate-person",
    },
    postInfo: {
      path: "quotation/info",
    },
    getPlansForQuotation: {
      path: "quotation/plans",
    },
    sendQuotationEmailByData: {
      path: "quotation/send-quotation-email/by-data",
    },
    closeQuotation: {
      path: "quotation/close/by-tracking-id",
    },
    createQuotationByTrackingId: {
      path: "quotation/by-tracking-id",
    },
    updateQuotationByTrackingId: {
      path: "quotation/update/by-tracking-id",
    },
    getManyInputsByCode: {
      path: "quotation/configuration/input/many-inputs-by-codes",
    },
    registerLopdp: {
      path: "integration/register-lopdp",
    },
    validateLopdp: {
      path: "integration/validate-lopdp",
    },
  },
  baseUrl: process.env.REACT_APP_QUOTATION_SERVICE,
  timeout: timeoutQuotation,
});
