import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  banks: [],
  brokers: [],
  quotationCreated: false,
  personFound: false,
  confredSelected: null,
  idNumber: null,
  passportNumber: null,
  checkLopdp: false,
  showLopdpCheck: false,
  showHelperText: false,
  lockButton: false,
};

export const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    setBanks: (state, action) => {
      state.banks = action.payload;
    },
    setBrokers: (state, action) => {
      state.brokers = action.payload;
    },
    toggleQuotationCreated(state) {
      state.quotationCreated = !state.quotationCreated;
    },
    togglePersonFound(state) {
      state.personFound = !state.personFound;
    },
    setConfiredSelected(state, action) {
      state.confredSelected = action.payload;
    },

    setIdNumber(state, action) {
      state.idNumber = action.payload;
    },
    setPassportNumber(state, action) {
      state.passportNumber = action.payload;
    },
    // todo elininar esto, corregir no se dbe  guardar de esta forma
    setCheckLopdp(state) {
      state.checkLopdp = !state.checkLopdp;
    },
    setCheckLopdpByValue(state, action) {
      state.checkLopdp = action.payload;
    },
    setShowLopdpCheck(state, action) {
      state.showLopdpCheck = action.payload;
    },
    setShowHelperText(state, action) {
      state.showHelperText = action.payload;
    },
    setLockButton(state, action) {
      state.lockButton = action.payload;
    },
  },
});

export const {
  setBanks,
  setBrokers,
  setIdNumber,
  setPassportNumber,
  setCheckLopdp,
  setCheckLopdpByValue,
  setLockButton,
  setShowHelperText,
  setShowLopdpCheck,
  setConfiredSelected,
  togglePersonFound,
  toggleQuotationCreated,
} = quotationSlice.actions;

export default quotationSlice.reducer;
