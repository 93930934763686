import { ProcessReturn } from "../axios/apiService.interface";

export default function validateOnlySuccess<T>(
  apiResponse: ProcessReturn<T>
): ProcessReturn<T> {
  const { response } = apiResponse;

  if (!response?.success) throw response?.error;

  return apiResponse;
}
