import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountTypes: null,
  accountOperationTypes: null,
  paymentMode: null,
  countries: null,
};

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setAccountTypes: (state, action) => {
      state.accountTypes = action.payload;
    },
    setAccountOperationTypes: (state, action) => {
      state.accountOperationTypes = action.payload;
    },
    setPaymentMode: (state, action) => {
      state.paymentMode = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const {
  setPaymentMode,
  setAccountTypes,
  setAccountOperationTypes,
  setCountries,
} = catalogSlice.actions;

export default catalogSlice.reducer;
