import backend from "app/data/backend";
import sponsorInfo from "app/data/configuration/brand/sponsorInfo.json";
import { GetProductByAdvanceFiltersCodesResult } from "app/interfaces/ProductService.interface";
import {
  Country,
  GetCatalogsByCodes,
  GetInfoDataParams,
  GetProductByAdvanceFiltersCodesParams,
} from "app/interfaces/QuotationService.interface";
import store from "app/store/index";
import { setQuotationInfo } from "app/store/slices/subscriptionSlice";
import {
  formatEndpoint,
  getValuesByKeyStartWith,
  sortArrayBy,
} from "app/utils";
import { sha256 } from "js-sha256";

import apiService from "../axios/apiService";
import injectKeyToParameters from "../process/injectKeyToParameters";
import { injectSignatureHeader } from "../process/injectSignatureHeader";
import sortPlansData from "../process/sortPlansData";
import validateOnlySuccess from "../process/validateOnlySuccess";

const { baseUrl: QUOTATION_BASE_URL, services } = backend.quotation;

export default {
  getProductByAdvanceFiltersCodes,
  getManyCatalogsByCode,
  getCountries,
  getValidateIdForQuotation,
  getInfoForQuotation,
  getPlansForQuotation,
  updateQuotationByTrackngId,
  sendQuotationEmailByData,
  createQuotationByTrackingId,
  closeQuotation,
  getManyInputsByCode,
  registerLopdp,
  validateLopdp,
};

function getProductByAdvanceFiltersCodes(
  params: GetProductByAdvanceFiltersCodesParams
) {
  const {
    channelCode,
    insuranceTypeCode,
    insuranceCarrierCode,
    contractTypeCode,
    flowCode,
  } = params;
  const { path, timeout } = services.getProductByConfiguredFiltersCode;

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [
        ["channelCode", channelCode],
        ["insuranceTypeCode", insuranceTypeCode],
        ["insuranceCarrierCode", insuranceCarrierCode],
        ["contractTypeCode", contractTypeCode],
        ["flowCode", flowCode],
      ],
    });

  return apiService.getData<GetProductByAdvanceFiltersCodesResult>(
    endpoint,
    timeout,
    {},
    [],
    [validateOnlySuccess]
  );
}

/**
 * Get catalogs by code
 * @param {object} filters Filters of catalog {code,includeItems,isActived}
 * @returns {object} Returns list if it is successful otherwise exception
 */
function getManyCatalogsByCode(params) {
  const { path, timeout } = services.getCatalogsByCodes;
  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [...params],
    });

  return apiService.getData<GetCatalogsByCodes>(
    endpoint,
    timeout,
    {},
    [],
    [validateOnlySuccess]
  );
}

/**
 * Service to get plans
 * @param data (trackId, productId, data:{birthDate,modality})
 * @returns plans
 */
function getPlansForQuotation(params) {
  const customParams = {
    data: params,
    propsToKey: [params.productId, params.channelCode, params.wayCode],
  };
  const { path, timeout } = services.getPlansForQuotation;
  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });

  return apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    {},
    [injectKeyToParameters],
    [validateOnlySuccess, sortPlansData]
  );
}

function sendQuotationEmailByData(params) {
  const { path, timeout } = services.sendQuotationEmailByData;
  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });
  const wayCode = sponsorInfo.channelWayCode;

  const { processData } = injectKeyToParameters({
    processData: {
      propsToKey: [params.productId, params.channelCode, wayCode],
    },
  });

  const signature = injectSignatureHeader(
    params,
    process.env.REACT_APP_ENCRYPT_PUBLIC_KEY_512
  );
  const paramsWithKey = {
    ...params,
    key: processData.key,
    wayCode,
  };

  return apiService.postData<any>(
    endpoint,
    timeout,
    paramsWithKey,
    { ...signature },
    [],
    [validateOnlySuccess]
  );
}

function getCountries() {
  const { path, timeout } = services.getCountries;
  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });

  return apiService.getData<{ countries: Country[] }>(endpoint, timeout);
}
// GetInfoDataParams
async function getValidateIdForQuotation(params: any) {
  const { path, timeout } = services.postValidateId;

  // destruture all params type GetInfoDataParams
  const { headers, filtered, ...rest } = params;
  const customData = rest.data;
  const customParams = {
    data: customData,
    propsToKey: [customData.data.identification, customData.data.planCode],
  };

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [["filtered", filtered]],
    });

  const response = await apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    headers,
    [injectKeyToParameters],
    [validateOnlySuccess]
  );

  const data = response.response.result;

  if (!filtered) {
    const banks = getValuesByKeyStartWith("banksConfiamed", data);
    const brokers = getValuesByKeyStartWith("brokersConfiamed", data);
    store.dispatch(setQuotationInfo({ banks, brokers }));
  }

  return data;
}

async function getInfoForQuotation(params: GetInfoDataParams) {
  const { path, timeout } = services.postInfo;

  // destruture all params type GetInfoDataParams
  const { headers, filtered, ...rest } = params;
  const customData = rest.data;
  const customParams = {
    data: customData,
    propsToKey: [customData.data.identification, customData.data.planCode],
  };

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [["filtered", filtered]],
    });

  const response = await apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    headers,
    [injectKeyToParameters],
    [validateOnlySuccess]
  );

  const data = response.response.result;

  if (!filtered) {
    const banks = getValuesByKeyStartWith("banksConfiamed", data);
    const brokers = getValuesByKeyStartWith("brokersConfiamed", data);
    store.dispatch(setQuotationInfo({ banks, brokers }));
  }

  const responseData = {
    datamallPerson: data.datamallPerson,
    trackingId: data.trackingId,
    plan: data.plan,
    isNullOrEmptyBirthDate: data.isNullOrEmptyBirthDate,
    isWrongGender: data.isWrongGender,
  };

  return responseData;
}

function createQuotationByTrackingId(params) {
  const { path, timeout } = services.createQuotationByTrackingId;

  const customParams = {
    data: params,
    propsToKey: [params.trackingId, params.data.planCode],
  };

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });

  return apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    {},
    [injectKeyToParameters],
    [validateOnlySuccess]
  );
}

function updateQuotationByTrackngId(params) {
  const { path, timeout } = services.updateQuotationByTrackingId;

  const customParams = {
    data: params,
    propsToKey: [params.trackingId, params.data.planCode],
  };

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });

  return apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    {},
    [injectKeyToParameters],
    [validateOnlySuccess]
  );
}

function closeQuotation(params) {
  const { path, timeout } = services.closeQuotation;

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [["trackingId", params.trackingId]],
    });

  // TODO remover any
  return apiService.getData<any>(
    endpoint,
    timeout,
    {},
    [],
    [validateOnlySuccess]
  );
}

async function getManyInputsByCode(params) {
  const { path, timeout } = services.getManyInputsByCode;

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [
        ["codes", params.code],
        ["includeItems", params.includeItems],
        ["isActived", params.isActived],
        ["filtered", params.filtered],
      ],
    });

  // TODO remover any
  const responseData = await apiService.getData<any>(
    endpoint,
    timeout,
    {},
    [],
    [validateOnlySuccess]
  );

  const response = responseData.response.result[0].parameters;
  sortArrayBy(response, "order");
  return response;
}

function registerLopdp(params) {
  const { path, timeout } = services.registerLopdp;

  const customParams = { ...params };

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });

  return apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    {
      "x-signature": sha256.hmac(params.identification, JSON.stringify(params)),
    },
    [],
    [validateOnlySuccess]
  );
}

async function validateLopdp(params) {
  const { path, timeout } = services.validateLopdp;
  const customParams = { ...params };

  const endpoint =
    QUOTATION_BASE_URL +
    formatEndpoint({
      path,
      query: [],
    });

  return apiService.postData<any>(
    endpoint,
    timeout,
    customParams,
    {
      "x-signature": sha256.hmac(params.identification, JSON.stringify(params)),
    },
    [],
    [validateOnlySuccess]
  );
}
