import { FC, PropsWithChildren } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "app/store";
import theme from "styles/themeConfig";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

const AppProviders: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  return (
    <GoogleReCaptchaProvider
      language="es-EC"
      useRecaptchaNet
      reCaptchaKey={reCaptchaKey}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              {children}
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  );
};

export default AppProviders;
