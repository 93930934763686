import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payerId: null, // titular or other
  payerData: {
    identification: null,
    name: null,
    lastName: null,
  },
};

export const payerSlice = createSlice({
  name: "payer",
  initialState,
  reducers: {
    setPayerId: (state, action) => {
      state.payerId = action.payload;
    },
    setPayerData: (state, action) => {
      state.payerData = action.payload;
    },
  },
});

export const { setPayerId, setPayerData } = payerSlice.actions;

export default payerSlice.reducer;
