import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

import {
  DecryptAESParams,
  EncryptAESParams,
  HashHmacSHA256Params,
} from "./SecurityUtils.interface";

/**
 * Function to encrypt data with a public key
 * @param dataToEncrypt Data stringify to encrypt
 * @param publicKey String publicKey secret
 * @returns String encrypt on success or false on failure
 */
export function encryptRSA(
  dataToEncrypt: string,
  publicKey: string
): string | false {
  const rsaEncryption = new JSEncrypt();
  rsaEncryption.setPublicKey(publicKey);
  return rsaEncryption.encrypt(dataToEncrypt);
}

/**
 * Function to decrypt data with a private key
 * @param dataToDecrypt Encrypted data
 * @param privateKey String privateKey secret
 * @returns String encrypt on success or false on failure
 */
export function decryptRSA(
  dataToDecrypt: string,
  privateKey: string
): string | false {
  const rsaEncryption = new JSEncrypt();
  rsaEncryption.setPrivateKey(privateKey);
  return rsaEncryption.decrypt(dataToDecrypt);
}

export function hashSHA256(data: string) {
  const result = CryptoJS.SHA256(data);
  return result.toString();
}

export function hashHmacSHA256(params: HashHmacSHA256Params) {
  const { data, key } = params;
  const result = CryptoJS.HmacSHA256(data, key);
  return result.toString();
}

export function encryptAES(params: EncryptAESParams) {
  const { data, key, options } = params;
  const result = CryptoJS.AES.encrypt(data, key, options);
  return result.toString(CryptoJS.format.OpenSSL);
}

export function decryptAES(params: DecryptAESParams) {
  const { data, key, options } = params;
  const result = CryptoJS.AES.decrypt(data, key, options);
  return result.toString(CryptoJS.enc.Utf8);
}

export function encodeBase64(data: string) {
  const utf8Data = CryptoJS.enc.Utf8.parse(data);
  return CryptoJS.enc.Base64.stringify(utf8Data);
}

export function decodeBase64(data: string) {
  const words = CryptoJS.enc.Base64.parse(data);
  return CryptoJS.enc.Utf8.stringify(words);
}

export function generateAESConfigFromUuid(uuid: string) {
  const formatReference = uuid.replaceAll("-", "");
  const shortReference = formatReference.slice(0, 16);

  return {
    secret: CryptoJS.enc.Utf8.parse(formatReference),
    options: {
      iv: CryptoJS.enc.Utf8.parse(shortReference),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    },
  };
}
