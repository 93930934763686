import { useLayoutEffect, useRef, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { TypographyVariantType } from "app/interfaces/SharedComponets.interface";
import { hasData } from "app/utils/index";
import clsx from "clsx";
import { Button, Typography } from "eva-lib";

import { AlertProps } from "./Alert.Interface";

const getVariants = (variant, customIcon, altCustomIcon, className) => {
  let variantStyles = "";
  let btnBorderStyles = "";
  let btnBackgroundStyles = "";
  let iconToRender = null;

  switch (variant) {
    case "success":
      variantStyles = "bg-success-100 outline-success-400";
      btnBorderStyles = "border-success-400 text-success-400";
      btnBackgroundStyles = "bg-success-400";
      iconToRender = (
        <CheckCircleIcon className="text-2xl mr-16 text-success-400" />
      );
      break;
    case "warning":
      variantStyles = "bg-warning-100 outline-warning-400";
      btnBorderStyles = "border-warning-400 text-warning-400";
      btnBackgroundStyles = "bg-warning-400";
      iconToRender = <ErrorIcon className="text-2xl mr-16 text-warning-400" />;
      break;
    case "error":
      variantStyles = "bg-error-100 outline-error-400";
      btnBorderStyles = "border-error-400 text-error-400";
      btnBackgroundStyles = "bg-error-400";
      iconToRender = <WarningIcon className="text-2xl mr-16 text-error-400" />;
      break;
    default:
      variantStyles = "bg-info-50 outline-info-500";
      btnBorderStyles = "border-info-500 text-info-500";
      btnBackgroundStyles = "bg-info-500";
      iconToRender = (
        <InfoIcon
          className={clsx("text-2xl mr-16 text-info-500", className.icon)}
        />
      );
      break;
  }

  if (customIcon) {
    iconToRender = (
      <Avatar className="mr-16 bg-primary-100">
        <img src={customIcon} alt={altCustomIcon} />
      </Avatar>
    );
  }

  return { variantStyles, btnBorderStyles, btnBackgroundStyles, iconToRender };
};

const getTypographyVariant = (variant, defaultValue) => {
  if (hasData(variant)) {
    return variant as TypographyVariantType;
  }
  return defaultValue as TypographyVariantType;
};

function Alert(props: AlertProps) {
  const [lineStyles, setlineStyles] = useState("");
  const divRef = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      if (divRef.current) {
        if (divRef.current.children.length === 1) {
          setlineStyles(
            divRef.current.children[0].clientHeight <= 22
              ? "md:h-[22px] h-16 items-center"
              : ""
          );
        }
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const { variantStyles, btnBorderStyles, btnBackgroundStyles, iconToRender } =
    getVariants(
      props.variant,
      props.customIcon,
      props.altCustomIcon,
      props.className
    );

  getTypographyVariant(props.className?.contentVariant, "body2");

  const titleVariant = getTypographyVariant(
    props.className?.titleVariant,
    "body2"
  );
  const contentVariant = getTypographyVariant(
    props.className?.contentVariant,
    "body2"
  );

  const getContentElement = () => {
    if (props.removeContentWrapper) {
      return props.content;
    }
    return (
      <Typography
        variant={contentVariant}
        className={{ root: "text-fontColor-300" }}
      >
        {props.content}
      </Typography>
    );
  };

  return (
    <div
      className={clsx(
        `flex w-auto max-w-full items-start rounded-lg p-16 outline outline-1 ${variantStyles} ${lineStyles} ${props.className?.root}`
      )}
    >
      {props.withIcon && iconToRender}
      <div className="flex h-full w-full flex-col justify-center" ref={divRef}>
        {props.title ? (
          <>
            <Typography
              variant={titleVariant}
              className={{ root: " text-14 mr-16 text-fontColor-300" }}
            >
              {props.title}
            </Typography>
            <div className="h-0 md:h-[1px]" />
            {getContentElement()}
            <div className="h-0 md:h-[1px]" />
          </>
        ) : (
          getContentElement()
        )}
        {props.withButtons && (
          <div className="mt-8">
            <Button
              variant="outlined"
              className={{
                root: `w-96 min-w-96 md:!w-128 md:!min-w-128 ${btnBorderStyles}`,
              }}
              style={{ marginRight: "16px" }}
              onClick={props.onCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className={{
                root: `w-96 min-w-96 md:!w-128 md:!min-w-128 ${btnBackgroundStyles}`,
              }}
              onClick={props.onConfirm}
            >
              Continuar
            </Button>
          </div>
        )}
      </div>
      {props.onClose && (
        <IconButton
          disableRipple
          className="h-14 w-14 ml-8 p-0"
          onClick={props.onClose}
        >
          <CloseIcon className="text-14 text-fontColor-300" />
        </IconButton>
      )}
    </div>
  );
}

export default Alert;
