import { memo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import clsx from "clsx";
import { Typography } from "eva-lib";

import { ProgressBarProps } from "./ProgressBar.interface";

function ProgressBar({ suffix, value, className, ...props }: ProgressBarProps) {
  const header = suffix ? `${Math.round(value)}% ${suffix}` : "";
  return (
    <>
      {header && (
        <div className="mb-xs min-w-space-l text-center">
          <Typography variant="body2" className={{ root: "text-primary-400" }}>
            {header}
          </Typography>
        </div>
      )}

      <LinearProgress
        className={clsx(
          "h-s w-full rounded-full bg-neutral-100",
          className?.linear
        )}
        classes={{ barColorPrimary: "bg-primary-500" }}
        variant="determinate"
        value={value}
        {...props}
      />
    </>
  );
}

export default memo(ProgressBar);
