import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Layout from "@health/Layout";
import Home from "app/containers/Home";
import protectedRoutes from "app/data/configuration/protected-routes.json";

export const publicUrl = process.env.PUBLIC_URL;
export const homeRoute = publicUrl || "/";

const HealthError404 = lazy(() => import("@health/Error404"));
const HealthError500 = lazy(() => import("@health/Error500"));

const ProductDetail = lazy(
  () => import("app/containers/ProductDetail/ProductDetail")
);

const RequestQuotationData = lazy(
  () => import("app/containers/Quotation/RequestQuotationData")
);

const SelectPlan = lazy(() => import("app/containers/Quotation/SelectPlan"));

const RequestIdentification = lazy(
  () => import("app/containers/Quotation/RequestTitularIdentification")
);

const DetailPreExistence = lazy(
  () => import("app/containers/Quotation/DetailPreExistence")
);

const RequestInfoTitular = lazy(
  () => import("app/containers/Quotation/RequestInfoTitular")
);

const PurchaseSummary = lazy(
  () => import("app/containers/Subscription/PurchaseSummary")
);

const RefundAccount = lazy(() => import("app/containers/Sale/RefundAccount"));

const BillingInformation = lazy(
  () => import("app/containers/Sale/BillingInformation")
);

const SelectBrokerAndPay = lazy(
  () => import("app/containers/Sale/SelectBrokerAndPay")
);

const PaymentProcessPage = lazy(
  () => import("app/containers/Payment/PaymentProcessPage")
);

const PaymentStatus = lazy(
  () => import("app/containers/Payment/PaymentStatus")
);

const getRouteByCodeStep = (codeStep) => {
  const route = {
    toConfig: "",
    toNavigate: "",
  };

  const { stepRoute } = protectedRoutes.find(
    (item) => item.codeStep === codeStep
  );

  route.toConfig = stepRoute;
  route.toNavigate =
    homeRoute === "/" ? "/".concat(stepRoute) : `${homeRoute}/${stepRoute}`;

  return route;
};

export const error404Route = getRouteByCodeStep("404");
export const error500Route = getRouteByCodeStep("500");

export const productDetailRoute = getRouteByCodeStep("productDetail");

export const requestQuotationDataRoute = getRouteByCodeStep(
  "requestQuotationDataRouteRoute"
);
export const selectPlanRoute = getRouteByCodeStep("selectPlanRoute");
export const requestIdentificationRoute = getRouteByCodeStep(
  "requestIdentificationRoute"
);

export const detailPreExistenceRoute = getRouteByCodeStep(
  "detailPreExistenceRoute"
);

export const requestInfoTitularRoute = getRouteByCodeStep(
  "requestInfoTitularRoute"
);

export const purchaseSummaryRoute = getRouteByCodeStep("purchaseSummaryRoute");
export const refundAccountRoute = getRouteByCodeStep("refundAccountRoute");
export const billingInformationRoute = getRouteByCodeStep(
  "billingInformationRoute"
);

export const selectBrokerAndPayRoute = getRouteByCodeStep(
  "selectBrokerAndPayRoute"
);

export const paymentProcessRoute = getRouteByCodeStep("paymentProcess");
export const paymentSuccessRoute = getRouteByCodeStep("paymentSuccess");
export const paymentErrorRoute = getRouteByCodeStep("paymentError");
export const paymentPendingRoute = getRouteByCodeStep("paymentPending");

function AppRoutes() {
  return (
    <>
      {useRoutes([
        {
          path: publicUrl,
          element: <Home />,
        },
        {
          path: publicUrl,
          element: <Layout />,
          children: [
            {
              path: productDetailRoute.toConfig,
              element: <ProductDetail />,
            },
            {
              path: requestQuotationDataRoute.toConfig,
              element: <RequestQuotationData />,
            },
            { path: selectPlanRoute.toConfig, element: <SelectPlan /> },
            {
              path: requestIdentificationRoute.toConfig,
              element: <RequestIdentification />,
            },
            {
              path: requestInfoTitularRoute.toConfig,
              element: <RequestInfoTitular />,
            },
            {
              path: detailPreExistenceRoute.toConfig,
              element: <DetailPreExistence />,
            },
            {
              path: purchaseSummaryRoute.toConfig,
              element: <PurchaseSummary />,
            },
            {
              path: refundAccountRoute.toConfig,
              element: <RefundAccount />,
            },
            {
              path: billingInformationRoute.toConfig,
              element: <BillingInformation />,
            },
            {
              path: selectBrokerAndPayRoute.toConfig,
              element: <SelectBrokerAndPay />,
            },
            {
              path: paymentProcessRoute.toConfig,
              element: <PaymentProcessPage />,
            },
            {
              path: paymentSuccessRoute.toConfig,
              element: <PaymentStatus status="success" />,
            },
            {
              path: paymentErrorRoute.toConfig,
              element: <PaymentStatus status="error" />,
            },
            {
              path: paymentPendingRoute.toConfig,
              element: <PaymentStatus status="pending" />,
            },
            { path: error404Route.toConfig, element: <HealthError404 /> },
            { path: error500Route.toConfig, element: <HealthError500 /> },
          ],
        },
        {
          path: `${publicUrl}/*`,
          element: <Navigate to={publicUrl} />,
        },
      ])}
    </>
  );
}

export default AppRoutes;
