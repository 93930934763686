import configuracion from "app/data/configuration/configuration.json";
import { createServiceGroup } from "app/utils/Service";

const timeoutConfiguration = configuracion.services.apiTimeout.configuration;

type ConfigurationServices = "getConfiredPlans";

export default createServiceGroup<ConfigurationServices>({
  services: {
    getConfiredPlans: {
      path: "confiamed-gmm/networks",
    },
  },
  timeout: timeoutConfiguration,
  baseUrl: process.env.REACT_APP_INTEGRATION_SERVICE,
});
