import { sortArrayBy } from "app/utils";

import { ProcessReturn } from "../axios/apiService.interface";

interface PlanResponse {
  plans: Array<any>;
  trackingId: string;
  documentUrl: string;
}

export default function sortPlansData(
  apiResponse: ProcessReturn<PlanResponse>
): ProcessReturn<PlanResponse> {
  const { response } = apiResponse;

  apiResponse.response.result.plans = sortArrayBy(
    response?.result.plans,
    "insuredValue"
  );

  return apiResponse;
}
