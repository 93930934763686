import React, { memo } from "react";
import clsx from "clsx";
import { TypographyFormat } from "eva-lib";

import CoverageAlertProps from "./CoverageAlert.interface";

function CoverageAlert({ icon, content, className }: CoverageAlertProps) {
  const icontElement = (
    <div className={clsx("mr-m h-xl min-h-40 w-xl min-w-40", className?.icon)}>
      {icon}
    </div>
  );

  return (
    <div
      className={clsx(
        "mb-m flex items-center rounded-lg border border-neutral-100 bg-primary-alternative1-50 py-xs px-s",
        className?.root
      )}
    >
      {icon && icontElement}
      <TypographyFormat
        variant="h5"
        className={{
          root: clsx("h5 text-neutral-800", className?.text),
        }}
        content={content}
      />
    </div>
  );
}

export default memo(CoverageAlert);
