import configuracion from "app/data/configuration/configuration.json";
import { createServiceGroup } from "app/utils/Service";

const timeoutSubscription = configuracion.services.apiTimeout.sale;

type SaleServices =
  | "getPaymentUrl"
  | "createContract"
  | "endSale"
  | "registerSale";

export default createServiceGroup<SaleServices>({
  services: {
    getPaymentUrl: {
      path: "sale/create-payment",
    },
    endSale: {
      path: "orchestrator/finalize-process-confiamed",
    },
    registerSale: {
      path: "sale/create",
    },
    createContract: {
      path: "sale/{0}/contract-confiamed",
    },
  },
  baseUrl: process.env.REACT_APP_SALE_SERVICE,
  timeout: timeoutSubscription,
});
