import { stringFormat } from "app/utils/Common";

import {
  CreateServiceGroup,
  EndpointQuery,
  FormatQueryBody,
  ServiceGroup,
} from "./ServiceUtils.interface";

const isLast = <T>(index: number, array: Array<T>) =>
  index === array.length - 1;

export function formatQuery(query: FormatQueryBody): string {
  if (!query.length) return "";

  return query.reduce((previousValue, currentValue, index, array) => {
    const lastString = !isLast(index, array) ? "&" : "";
    const [queryKey, queryValue] = currentValue;
    return `${previousValue}${queryKey}=${queryValue}${lastString}`;
  }, "?");
}

export function formatEndpoint<T extends string | number>(
  endpointQuery: EndpointQuery,
  params: Array<T> = []
): string {
  // if dont have query
  if (!endpointQuery?.query.length) {
    return stringFormat(endpointQuery.path, params);
  }
  const { path, query } = endpointQuery;
  const fullUrl = path + formatQuery(query);
  return stringFormat(fullUrl, params);
}

export function createServiceGroup<T extends string>(
  createService: CreateServiceGroup<T>
): ServiceGroup<T> {
  const { services, timeout } = createService;

  type KeysServices = keyof typeof services;

  type MapServices = ServiceGroup<KeysServices>["services"];

  const mapServices = Object.keys(services).reduce<MapServices>(
    (previous, current) => {
      const currentKey = current as KeysServices;

      return {
        ...previous,
        [currentKey]: {
          path: services[currentKey].path,
          query: services[currentKey]?.query ?? [],
          timeout: services[currentKey]?.timeout ?? timeout,
        },
      };
    },
    {} as MapServices
  );

  return {
    ...createService,
    services: mapServices,
  };
}
