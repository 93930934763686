import { toast } from "react-toastify";
import HealthAlert from "@health/Alert";

interface ToastDataProps {
  id: string | number;
  title: string;
  variant: string;
  content: string;
  autoClose?: number;
  closeToast: () => void;
}

const showWarning = (toastData: ToastDataProps) => {
  const { id, title, variant, content, autoClose, closeToast } = toastData;
  toast.warning(
    <HealthAlert
      title={<strong>{title}</strong>}
      variant={variant}
      withIcon
      onClose={closeToast}
      content={content}
    />,
    {
      autoClose: autoClose || 5000,
      toastId: id,
    }
  );
};

const showSuccess = (toastData: ToastDataProps) => {
  const { id, title, variant, content, autoClose, closeToast } = toastData;
  toast.success(
    <HealthAlert
      title={title}
      variant={variant}
      withIcon
      onClose={closeToast}
      content={content}
    />,
    {
      autoClose: autoClose || 5000,
      toastId: id,
    }
  );
};

export default {
  showWarning,
  showSuccess,
};
