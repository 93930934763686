import { useCallback } from "react";
import Particles, { IParticlesProps } from "react-tsparticles";
// @ts-ignore
import heroImage from "app/assets/hero/familia-final.webp";
import { Button, Container as EvaContainer } from "eva-lib";
import { loadFull } from "tsparticles";
import type { Container, Engine } from "tsparticles-engine";

function ParticlesContainer(props: unknown) {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const options: IParticlesProps["options"] = {
    fullScreen: false,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
      },
      modes: {
        push: {
          quantity: 10,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#5DCEFF",
      },
      collisions: {
        enable: false,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "out",
        },
        random: false,
        speed: 5,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 10,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: {
          min: 8,
          max: 40,
        },
      },
    },
  };
  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {},
    []
  );

  return (
    <Particles
      className="absolute h-full w-full overflow-hidden md:h-screen-4/5"
      canvasClassName="absolute !h-[1920px] "
      options={options}
      init={particlesInit}
      loaded={particlesLoaded}
    />
  );
}

function HeroSection(props) {
  return (
    <section className="relativeg mb-l w-full overflow-hidden bg-primary-50">
      <ParticlesContainer />
      <EvaContainer
        className={{
          content: "flex h-full flex-row flex-col md:h-screen-4/5 md:flex-row",
        }}
      >
        <div className=" my-xl flex w-full justify-center md:w-1/2">
          <img
            src={heroImage}
            alt="confiplus hero"
            className="h-full w-3/4 -rotate-15 rounded-xl bg-secondary-400 md:w-auto md:scale-125 lg:scale-150"
          />
        </div>
        <div className="z-50 mb-xl box-border w-full self-center md:m-0 md:w-1/2 md:p-s-l">
          <div className="mb-l">
            <span className="rounded-md bg-secondary-500 px-m py-xs font-semibold">
              Confiplus
            </span>
          </div>
          <h1 className="mb-l font-medium">
            Confiplus, el seguro médico a tu medida
          </h1>
          <h5 className="mb-m font-normal">
            Escoge la red médica y la cobertura de maternidad según tu
            necesidad.
          </h5>
          <Button
            color="primary"
            id="quote"
            variant="contained"
            onClick={props.navigateToNext}
            className={{ root: "body1 font-semibold" }}
            data-tesid="button-quote"
          >
            Cotizar
          </Button>
        </div>
      </EvaContainer>
    </section>
  );
}

export default HeroSection;
