import { memo } from "react";
import EvaTag from "@eva/EvaTag";
import clsx from "clsx";
import { Typography } from "eva-lib";

interface ClassNameProps {
  root?: string;
  item?: {
    container?: string;
    typography?: string;
  };
}
interface SitWaitingTimeProps {
  waitingTimes: WaitingTime[];
  className?: Partial<ClassNameProps>;
}

interface WaitingTime {
  id: string;
  time: string;
  timeUnit: string;
  description: string;
}

function WaitingTimeItem({ waitingTime, className }) {
  return (
    <>
      <EvaTag
        className={{
          root: "mr-s min-w-[72px] rounded-[10px] bg-primary-alternative1-50 text-center",
          text: "body2 text-primary-alternative1-500",
        }}
        text={`${waitingTime.time} ${waitingTime.timeUnit}`}
      />
      <Typography variant="body1" className={className?.typography}>
        {waitingTime.description}
      </Typography>
    </>
  );
}

function WaitingTimes({ className, waitingTimes }: SitWaitingTimeProps) {
  return (
    <div className={className?.root || "mb-l md:mb-0"}>
      <ul className="m-0 p-0">
        {waitingTimes.map((waitingTime, index, arr) => (
          <li
            key={waitingTime.id}
            className={clsx("flex items-center p-0", {
              "mb-xs": index !== arr.length - 1,
            })}
          >
            <WaitingTimeItem
              waitingTime={waitingTime}
              className={className?.item}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default memo(WaitingTimes);
