function FeminineIcon(props) {
  return (
    <svg
      width="92"
      height="91"
      viewBox="0 0 92 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <path
        d="M91.5 45.5C91.5 70.629 71.129 91 46 91C20.871 91 0.5 70.629 0.5 45.5C0.5 20.371 20.871 0 46 0C71.129 0 91.5 20.371 91.5 45.5Z"
        fill="#E4F2FA"
      />
      <path
        d="M42.1875 70.9166V56.9374H34.5625L41.0438 36.0958C41.4674 35.0791 42.124 34.2742 43.0135 33.6812C43.9031 33.0881 44.8986 32.7916 46 32.7916C47.1014 32.7916 48.0969 33.0881 48.9865 33.6812C49.876 34.2742 50.5326 35.0791 50.9562 36.0958L57.4375 56.9374H49.8125V70.9166H42.1875ZM46 29.3603C44.7292 29.3603 43.6384 28.905 42.7276 27.9942C41.8168 27.0834 41.3615 25.9926 41.3615 24.7218C41.3615 23.451 41.8168 22.3602 42.7276 21.4494C43.6384 20.5386 44.7292 20.0833 46 20.0833C47.2708 20.0833 48.3616 20.5386 49.2724 21.4494C50.1832 22.3602 50.6385 23.451 50.6385 24.7218C50.6385 25.9926 50.1832 27.0834 49.2724 27.9942C48.3616 28.905 47.2708 29.3603 46 29.3603Z"
        fill="#0057D1"
      />
    </svg>
  );
}

export default FeminineIcon;
