import TagManager from "react-gtm-module";
import { ToastContainer, ToastPosition } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import AppProviders from "app/AppProviders";
import shared from "app/data/configuration/shared.json";

import AppRoutes from "./routes";

injectStyle();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (tagManagerArgs.gtmId) {
  TagManager.initialize(tagManagerArgs);
}

function App() {
  const { toastGlobal } = shared;
  return (
    <>
      <ToastContainer
        className="toast left-1/2 w-auto -translate-x-1/2 pt-88 md:pt-8"
        toastClassName="p-0 bg-transparent shadow-none"
        position={toastGlobal.position as ToastPosition}
        autoClose={toastGlobal.autoClose}
        hideProgressBar={toastGlobal.hideProgressBar}
        newestOnTop={toastGlobal.newestOnTop}
        closeOnClick={toastGlobal.closeOnclick}
        rtl={toastGlobal.rtl}
        icon={toastGlobal.icon}
        pauseOnFocusLoss={toastGlobal.pauseOnFocusLoss}
        draggable={toastGlobal.draggable}
        closeButton={toastGlobal.closeButton}
        pauseOnHover={toastGlobal.pauseOnHover}
      />
      <AppProviders>
        <AppRoutes />
      </AppProviders>
    </>
  );
}

export default App;
