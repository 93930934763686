import clsx from "clsx";

interface EvaTagProps {
  text: string;
  variant?: "filled" | "outline";
  className?: Partial<ClassNameProps>;
}

interface ClassNameProps {
  root: string;
  text: string;
}

const tagClasess = {
  filled: "bg-neutral-900 text-white",
  outline: "bg-white text-neutral-900 border border-solid border-current",
};

function EvaTag(props: EvaTagProps) {
  const { text, variant = "filled", className } = props;

  return (
    <div
      className={clsx(
        tagClasess[variant],
        "body2 box-border inline-block rounded-lg px-xs py-2xs font-bold ",
        className?.root
      )}
    >
      <span className={clsx(className?.text)}>{text}</span>
    </div>
  );
}

export default EvaTag;
