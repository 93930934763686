import { memo, SyntheticEvent, useCallback, useMemo, useState } from "react";
import EvaAccordion from "@eva/EvaAccordion";
import EvaAccordionDetails from "@eva/EvaAccordionDetails";
import EvaAccordionTitle from "@eva/EvaAccordionTitle";
import SitWaitingTimes from "@health/WaitingTimes";
import faqSectionInfo from "app/data/containers/home/faqs.json";
import { parseJsonToHtml } from "app/utils/JsonConverter";
import { Typography } from "eva-lib";

export interface FaqProps {
  id: number;
  questionText: string;
  answer: any;
  waitingTimes?: any;
}

function Questions() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = useCallback(
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  const waitingTimesClasses = useMemo(
    () => ({
      item: {
        typography: "my-auto",
        container: "mr-s-l mt-xs w-[72px]",
      },
    }),
    []
  );

  return (
    <>
      {faqSectionInfo.map((item: FaqProps) => (
        <EvaAccordion
          key={`faq-panel-header-${item.id}`}
          expanded={expanded === `panel${item.id}`}
          onChange={handleChange(`panel${item.id}`)}
        >
          <EvaAccordionTitle
            data-testid={`faq-panel-header-${item.id}`}
            aria-controls={`faq-header-${item.id}`}
            className={{ expandIcon: "text-neutral-900" }}
          >
            <Typography
              variant="body1"
              className={{ root: "my-0 font-semibold" }}
            >
              {item.questionText}
            </Typography>
          </EvaAccordionTitle>
          <EvaAccordionDetails>
            <div className="body1 mb-m text-neutral-900 md:mx-m">
              {parseJsonToHtml(item.answer, null, true)}
              {!!item.waitingTimes && (
                <SitWaitingTimes
                  waitingTimes={item.waitingTimes}
                  className={waitingTimesClasses}
                />
              )}
            </div>
          </EvaAccordionDetails>
        </EvaAccordion>
      ))}
    </>
  );
}

export default memo(Questions);
