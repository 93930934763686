import get from "lodash.get";

import {
  FunctionNameAndArgs,
  SmartFormatCallback,
} from "./SmartFormat.interface";

function removeSpaces(str: string, size: number): string {
  const some = str.slice(size, str.length - size);
  return some;
}

function getFunctionNameAndArgs(value: string): FunctionNameAndArgs {
  const splicedCurFormat: Array<string> = value.split("(");

  const functionName: string = splicedCurFormat[0];

  const functionArgs: Array<string> = splicedCurFormat[1]
    .slice(0, -1)
    .split(",");

  return { functionName, functionArgs };
}

function smartFormat(
  content: string,
  context: Object,
  formats: Record<string, SmartFormatCallback>
): string {
  const vars = content.match(/\{{(.*?)\}}/gm);

  if (!vars) return content;

  return vars?.reduce((prevContent, currentVar) => {
    const [propertyPath, ...formatCallbacks] = removeSpaces(
      currentVar,
      2
    ).split("::");

    const value: string = get(context, propertyPath, "");

    const valueResult = formatCallbacks.reduce((prevValue, format) => {
      const { functionName, functionArgs } = getFunctionNameAndArgs(format);

      if (formats[functionName]) {
        const result = formats[functionName](prevValue, ...functionArgs);
        return result;
      }

      return prevValue;
    }, value);

    return prevContent.replaceAll(currentVar, valueResult);
  }, content);
}

export default smartFormat;
