/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx";
import { useSwiper } from "swiper/react";

interface CustomDotsProps {
  slideIndex: number;
  onClick: (index: number) => void;
}

function CustomDots(props: CustomDotsProps) {
  const { slideIndex, onClick } = props;

  const currentSwiper = useSwiper();

  const groups = currentSwiper.params.slidesPerGroup;

  const length = Math.ceil(currentSwiper.slides.length / groups);

  const arrayDots = [...Array(length)].map((_item, ind) => ind);

  const HandleClick = (index: number) => () => {
    currentSwiper.slideTo(index);
    onClick(index);
  };

  return (
    <div className="flex justify-center">
      <div className="flex pt-m">
        {arrayDots.map((dot) => (
          <div
            key={dot}
            onClick={HandleClick(dot)}
            className={clsx(
              "mr-2xs h-xs w-xs cursor-pointer rounded-[30px] transition last:mr-0",
              slideIndex === dot * groups
                ? "!w-32 bg-primary-alternative1-500"
                : "bg-primary-alternative1-100"
            )}
          />
        ))}
      </div>
    </div>
  );
}

export default CustomDots;
