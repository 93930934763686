import { memo } from "react";
import HomeInfo from "app/data/containers/home/page.json";
import { Button, Container, Typography, TypographyFormat } from "eva-lib";

import Questions from "./Questions";

const faqSectionInfo = HomeInfo.faqSection;

function FaQSection(props) {
  const { handleClick } = props;
  return (
    <section className="py-2xl">
      <Container
        className={{
          content: "grid grid-cols-12 gap-x-s-l",
        }}
      >
        <div className="col-span-12 text-center md:col-span-8 md:col-start-3">
          <TypographyFormat
            variant="h3"
            content={faqSectionInfo.title}
            className={{
              root: "pb-l text-center font-semibold text-neutral-900",
            }}
          />
          <Typography
            variant="body1"
            component="p"
            className={{ root: "h5 pb-m font-normal" }}
          >
            {faqSectionInfo.subTitle}
          </Typography>
        </div>
        <div className="col-span-12 md:col-span-8 md:col-start-3">
          <Questions />
        </div>
        <div className="col-span-12 mt-l md:col-span-4 md:col-start-5 md:px-xl xl:col-span-2 xl:col-start-6 xl:px-0">
          {faqSectionInfo.buttonText && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              className={{ root: "body1 py-s font-semibold" }}
              data-testid="button-faq-showmore"
            >
              {faqSectionInfo.buttonText}
            </Button>
          )}
        </div>
      </Container>
    </section>
  );
}

export default memo(FaQSection);
