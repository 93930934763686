module.exports = {
  current: "currentColor",
  transparent: "transparent",
  black: "#000000",
  white: "#FFFFFF",

  header: "#3D90BF",
  footer: "#F4F4F4",
  background: "#f5f0ff",

  neutral: {
    50: "#F4F4F4",
    100: "#EEEEEE",
    200: "#E9E9E9",
    300: "#DADADA",
    400: "#B6B6B6",
    500: "#999999",
    600: "#707070",
    700: "#5C5C5C",
    800: "#3E3E3E",
    900: "#1E1E1E",
    alternative1: {
      50: "#FFF",
      100: "#FFF",
      200: "#FFF",
      300: "#FFF",
      400: "#FFF",
      500: "#FFFFFF",
      600: "#D1D1D1",
      700: "#BBBBBB",
      800: "#989898",
      900: "#727272",
    },
  },
  primary: {
    50: "#BBEAFF",
    100: "#A4E4FF",
    200: "#85DBFF",
    300: "#5DCEFF",
    400: "#27BEFF",
    500: "#009ADE",
    600: "#0084BD",
    700: "#0070A0",
    800: "#005F88",
    900: "#005174",
    alternative1: {
      50: "#E4F2FA",
      100: "#DBEDF8",
      200: "#CFE7F5",
      300: "#BFDFF2",
      400: "#A9D5EE",
      500: "#8DC6E8",
      600: "#5EB0DF",
      700: "#379CD7",
      800: "#2687BE",
      900: "#2173A2",
    },
    alternative2: {
      50: "#ADDCFF",
      100: "#92D0FF",
      200: "#6EC0FF",
      300: "#3DABFF",
      400: "#008FFC",
      500: "#005DA6",
      600: "#00508D",
      700: "#004478",
      800: "#003A66",
      900: "#003157",
    },
    alternative3: {
      50: "#E7E9EE",
      100: "#80B5FF",
      200: "#569CFF",
      300: "#1E7BFF",
      400: "#0057D1",
      500: "#002E6D",
      600: "#00275D",
      700: "#00214F",
      800: "#001C43",
      900: "#001839",
    },
    alternative4: {
      50: "#E7E9EE",
      100: "#CFD4DE",
      200: "#9FA8BE",
      300: "#6F7D9D",
      400: "#3F517D",
      500: "#0F265C",
      600: "#0C1F50",
      700: "#091944",
      800: "#061239",
      900: "#030C2D",
    },
  },
  secondary: {
    50: "#FEEDCC",
    100: "#FDE7BB",
    200: "#FDE0A5",
    300: "#FCD587",
    400: "#FBC75F",
    500: "#FAB529",
    600: "#F2A306",
    700: "#CE8B05",
    800: "#AF7604",
    900: "#956403",
    alternative1: {
      50: "#F9D6D1",
      100: "#F7C8C2",
      200: "#F5B6AD",
      300: "#F29D92",
      400: "#ED7D6E",
      500: "#E7503D",
      600: "#DD321B",
      700: "#BC2A17",
      800: "#A02414",
      900: "#881F11",
    },
    alternative2: {
      50: "#DBECD0",
      100: "#CFE6C0",
      200: "#BFDDAB",
      300: "#AAD28F",
      400: "#8DC36A",
      500: "#6AA542",
      600: "#5A8C38",
      700: "#4C7730",
      800: "#416529",
      900: "#375622",
    },
    alternative3: {
      50: "#F6CCCD",
      100: "#F3BBBD",
      200: "#EFA4A7",
      300: "#E98689",
      400: "#E25E62",
      500: "#D8232E",
      600: "#B82126",
      700: "#9D1C21",
      800: "#85181C",
      900: "#711518",
    },
    alternative4: {
      50: "#F9FAFC",
      100: "#F4F6F9",
      200: "#E9ECF3",
      300: "#DEE3ED",
      400: "#D3D9E7",
      500: "#C7CFE1",
      600: "#BEC7DB",
      700: "#B5BFD6",
      800: "#ADB8D1",
      900: "#A4B0CB",
    },
  },
  warning: {
    50: "#FFDBC1",
    100: "#FFCEAC",
    200: "#FFBE90",
    300: "#FFA96B",
    400: "#FF8C3A",
    500: "#F76800",
    600: "#D25700",
    700: "#B24A00",
    800: "#983F00",
    900: "#813600",
  },
  error: {
    50: "#FFD3D5",
    100: "#FFC4C7",
    200: "#FFB0B4",
    300: "#FF969C",
    400: "#FF737A",
    500: "#FF454F",
    600: "#FF1420",
    700: "#EA000C",
    800: "#C7000A",
    900: "#A90008",
  },
  success: {
    50: "#B5FFDF",
    100: "#9DFFD4",
    200: "#7CFFC6",
    300: "#50FFB3",
    400: "#16FF9A",
    500: "#00C772",
    600: "#00A960",
    700: "#009051",
    800: "#007A45",
    900: "#00683B",
  },
  info: {
    50: "#C9F7FF",
    100: "#B7F4FF",
    200: "#9EF1FF",
    300: "#7EECFF",
    400: "#53E5FF",
    500: "#1ADEFF",
    600: "#00CBEF",
    700: "#00ADCB",
    800: "#0093AD",
    900: "#007D93",
  },
  fontColor: {
    50: "#838383",
    100: "#838383",
    200: "#323232",
    300: "#211c26",
    400: "#211c26",
    500: "#211c26",
    600: "#FFFFFF",
  },
};
