import CoverageAlert from "@health/CoverageAlert";
import CoverageCard from "@health/CoverageCard/";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
// @ts-ignore
import coverageIconAlert from "app/assets/card/cobertura-home.webp";
import HomeInfo from "app/data/containers/home/page.json";
import defaultProductInfo from "app/data/containers/home/product-default-values.json";
import selectPlanInfo from "app/data/containers/quotation/select-plan-page.json";
import { generateDynamicItems, hasData } from "app/utils";
import clsx from "clsx";
import { Container, Typography, TypographyFormat } from "eva-lib";

const coverageInfo = HomeInfo.coverageSection;
const { coverageAccepted } = coverageInfo;
const { coverageRejected } = coverageInfo;
const defaultProductInfoItemsAccepted =
  defaultProductInfo.coverageSection.coverageAccepted.itemsList;
const defaultProductInfoItemsRejected =
  defaultProductInfo.coverageSection.coverageRejected.itemsList;
const homeDefaultItem = HomeInfo.defaultItem;

const getSizeCard = (product) => {
  let numberItems = 0;
  if (hasData(product)) {
    numberItems =
      product?.coverages.length >= product?.exclusions.length
        ? product.coverages.length
        : product.exclusions.length;
    numberItems = coverageInfo.coverageAccepted.coverageNotification
      .showNotification
      ? numberItems + 2
      : numberItems;
  }
  return numberItems;
};

const getTitleCard = (typeCard) => {
  const title =
    typeCard === "accepted"
      ? coverageInfo.coverageAccepted.title.normalTitle
      : coverageInfo.coverageRejected.title.normalTitle;
  return (
    <Typography variant="h3" className={{ root: "h3 font-semibold" }}>
      {title}
    </Typography>
  );
};

const getClassNameCard = (typeCard) => {
  const borderColor =
    typeCard === "accepted" ? "border-success-400" : "border-error-400";
  const listItem = "px-0 pb-0 pt-m";
  const list = "py-0";

  const root = `border border-solid rounded-lg px-s-m py-m ${borderColor}`;
  const className = { root, list, listItem };
  return className;
};

const getAvatar = (typeCard) => {
  const avatar =
    typeCard === "accepted" ? (
      <Avatar className="mr-s h-xl w-xl bg-success-50 text-success-500">
        <CheckCircleOutlineIcon />
      </Avatar>
    ) : (
      <Avatar className="mr-s h-xl w-xl bg-error-50 text-error-500">
        <CloseIcon />
      </Avatar>
    );
  return avatar;
};

const alertCoverageIcon = <img src={coverageIconAlert} alt="card cover icon" />;

function CoverageSection({ product }) {
  const sizeCard = getSizeCard(product);
  const coverageCartAccept = (
    <div className="col-span-12 md:col-span-6">
      {product && (
        <CoverageCard
          title={getTitleCard(coverageAccepted.variant)}
          avatar={getAvatar(coverageAccepted.variant)}
          customIcons={coverageAccepted.customIcons}
          sizeCard={sizeCard}
          coverageList={generateDynamicItems(
            coverageAccepted.coveragesToShow,
            product.coverages,
            defaultProductInfoItemsAccepted,
            "coverage",
            homeDefaultItem
          )}
          className={getClassNameCard(coverageAccepted.variant)}
          notification={coverageInfo.coverageAccepted.coverageNotification}
        />
      )}
    </div>
  );
  const coverageCartExclusions = (
    <div className="col-span-12 md:col-span-6">
      {product && (
        <CoverageCard
          title={getTitleCard(coverageRejected.variant)}
          avatar={getAvatar(coverageRejected.variant)}
          sizeCard={sizeCard}
          coverageList={generateDynamicItems(
            coverageInfo.coverageRejected.exclusionsToShow,
            product.exclusions,
            defaultProductInfoItemsRejected,
            "coverage",
            homeDefaultItem
          )}
          className={getClassNameCard(coverageRejected.variant)}
        />
      )}
    </div>
  );

  return (
    <section className="mb-2xl">
      <Container>
        <TypographyFormat
          variant="h3"
          content={coverageInfo.title}
          className={{
            root: "pb-l text-center font-semibold text-neutral-900",
          }}
        />
        {selectPlanInfo.showCoverageAlert && (
          <CoverageAlert
            icon={alertCoverageIcon}
            content={selectPlanInfo.coverageAlertContent}
            className={{
              text: "font-normal",
              root: "!border-neutral-400 bg-white",
            }}
          />
        )}

        <div className={clsx("container grid gap-s-l md:grid-cols-12")}>
          {coverageCartAccept}
          {coverageCartExclusions}
        </div>
      </Container>
    </section>
  );
}

export default CoverageSection;
