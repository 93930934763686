import configuration from "app/data/configuration/configuration.json";
import _ from "lodash";

export * from "./Attributes";
export * from "./Common";
export * from "./Date";
export * from "./HealtCustoms";
export * from "./JsonConverter";
export * from "./Security";
export * from "./Service";
export * from "./SmartFormat";
export * from "./Validations";
const REACT_APP_PREFIX = "REACT_APP_";

export const getValuesByKeyStartWith = (startWith, object) => {
  const keys = Object.keys(object);
  const keyName = keys.filter((key) => key.startsWith(startWith));
  return object[keyName[0]];
};

export function transactionTypeText(transactionType, isLowerCase?) {
  let text = transactionType === "P" ? "Pago" : "Suscripción";
  if (isLowerCase) {
    text = text.toLowerCase();
  }
  return text;
}

export function checkStringBoolValue(valueToCheck, positiveString) {
  return typeof valueToCheck === "boolean"
    ? valueToCheck
    : valueToCheck === positiveString;
}

export const windowOpenLink = (link: string | URL, target?: string) => {
  window.open(link, target || "_blank");
};

export const calcTimeLeft = (time: Date, timerRest: number) => {
  const timeNow = new Date();
  const difference = Math.floor(timeNow.getTime() - time.getTime());
  return Math.floor(timerRest - difference / 1000);
};

export const getFromEnvFile = (
  key: string,
  prefix: string = REACT_APP_PREFIX
): string => {
  key = `${prefix}${key}`;
  const value = process.env[key];
  return value || "";
};

export const getErrorMessage = (code) => {
  const error = configuration.errorsCode.flowErrors.find(
    (element) => element.code === code
  );
  let msg = "";
  if (error) {
    msg = _.isString(error.text) ? error.text : JSON.stringify(error.text);
  }
  return msg || false;
};

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
