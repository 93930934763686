import { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import clsx from "clsx";

interface EvaAccordionSummaryProps
  extends Omit<AccordionSummaryProps, "className"> {
  children: ReactNode | ReactNode[];
  className?: Partial<ClassNameProps>;
}

// root: component title
// expandIcon: styles to expand icon component Ex: text-primary-alternative1-600
interface ClassNameProps {
  root: string;
  expandIcon: string;
}

export default function EvaAccordionTitle({
  children,
  className,
  ...props
}: EvaAccordionSummaryProps) {
  return (
    <AccordionSummary
      expandIcon={
        <ExpandMoreIcon className={clsx("h-l w-l", className?.expandIcon)} />
      }
      classes={{
        root: clsx("py-m", className?.root),
      }}
      {...props}
    >
      {children}
    </AccordionSummary>
  );
}
