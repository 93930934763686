function MaleIcon(props) {
  return (
    <svg
      width="92"
      height="91"
      viewBox="0 0 92 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <path
        d="M91.5 45.5C91.5 70.629 71.129 91 46 91C20.871 91 0.5 70.629 0.5 45.5C0.5 20.371 20.871 0 46 0C71.129 0 91.5 20.371 91.5 45.5Z"
        fill="#E4F2FA"
      />
      <path
        d="M41.5013 70.9166V53.1249H36.418V37.0489C36.418 35.9899 36.7886 35.0897 37.5299 34.3484C38.2713 33.607 39.1714 33.2364 40.2305 33.2364H50.3971C51.4562 33.2364 52.3563 33.607 53.0977 34.3484C53.839 35.0897 54.2096 35.9899 54.2096 37.0489V53.1249H49.1263V70.9166H41.5013ZM45.3138 29.3603C44.043 29.3603 42.9522 28.905 42.0414 27.9942C41.1306 27.0834 40.6753 25.9926 40.6753 24.7218C40.6753 23.451 41.1306 22.3602 42.0414 21.4494C42.9522 20.5386 44.043 20.0833 45.3138 20.0833C46.5846 20.0833 47.6754 20.5386 48.5862 21.4494C49.497 22.3602 49.9523 23.451 49.9523 24.7218C49.9523 25.9926 49.497 27.0834 48.5862 27.9942C47.6754 28.905 46.5846 29.3603 45.3138 29.3603Z"
        fill="#0057D1"
      />
    </svg>
  );
}
export default MaleIcon;
