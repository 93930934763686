import HealthAlert from "@health/Alert/";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { optionRemoveRootParrNode } from "app/utils";
import { parseJsonToHtml } from "app/utils/JsonConverter";
import clsx from "clsx";
import { Typography } from "eva-lib";

import { CoverageCardProps } from "./CoverageCard.Interface";

const location = process.env.PUBLIC_URL;
function CoverageCard({
  title,
  avatar,
  sizeCard,
  coverageList,
  customIcons,
  className,
  notification,
  ...props
}: CoverageCardProps) {
  return (
    <div className={clsx(className?.root, "h-full")}>
      {title}
      <div {...props}>
        <List className={className?.list}>
          {coverageList.map(({ body, id }) => {
            const customIcon = customIcons?.find((icon) => icon.code === id);
            return (
              <ListItem key={id} className={className?.listItem}>
                <ListItemAvatar className={className?.listItemAvatar}>
                  {customIcon ? (
                    <Avatar
                      className="mr-s h-xl w-xl bg-success-50 text-success-500 "
                      classes={{ img: "w-auto h-auto" }}
                      src={location + customIcon.icon}
                    />
                  ) : (
                    avatar
                  )}
                </ListItemAvatar>
                <ListItemText>
                  <Typography
                    variant="h5"
                    className={{ root: "h5 font-normal" }}
                  >
                    {parseJsonToHtml(body, optionRemoveRootParrNode)}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>

        {notification?.showNotification && (
          <div className={clsx("mt-m w-auto max-w-full")}>
            <HealthAlert
              className={{
                root: "bg-primary-alternative1-50 outline-primary-500",
              }}
              variant={notification.info}
              content={parseJsonToHtml(
                notification.body,
                optionRemoveRootParrNode
              )}
              withIcon
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CoverageCard;
