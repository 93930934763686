import configuracion from "app/data/configuration/configuration.json";
import { createServiceGroup } from "app/utils/Service";

const timeoutProduct = configuracion.services.apiTimeout.product;

type ProductServices =
  | "getProductByConfiguredFiltersCode"
  | "getProductById"
  | "getProductByCode";

export default createServiceGroup<ProductServices>({
  services: {
    getProductById: {
      path: "product/{0}",
    },
    getProductByCode: {
      path: "product/{0}/by-code",
    },
    getProductByConfiguredFiltersCode: {
      path: "product/get-by-advance-filters-codes",
    },
  },
  baseUrl: process.env.REACT_APP_PRODUCT_SERVICE,
  timeout: timeoutProduct,
});
