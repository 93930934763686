import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import HomeHealth from "app/assets/ConfiRed/ConfiRedIcon";
import FeminineIcon from "app/assets/Gender/FemenineIcon";
import MaleIcon from "app/assets/Gender/MaleIcon";
import MaternityIcon from "app/assets/Maternity/MaternityIcon";
import NoMaternityIcon from "app/assets/Maternity/NoMaternityIcon";
import configuration from "app/data/configuration/configuration.json";
import SharedInfo from "app/data/configuration/shared.json";
import { error500Route } from "app/routes";
import { Typography } from "eva-lib";

import { sortArrayBy } from "../Common";
import {
  getEvaNodeTextByPosition,
  optionRemoveRootParrNode,
  parseJsonToHtml,
} from "../JsonConverter";

export const GET_DATA_BY_TRACKING_ID = "trackingId";
export const GET_DATA_BY_GENDER = "gender";
export const GET_DATA_BY_AGE_AND_GENDER = "ageAndGender";

/**
 * Prevent default event (FORM)
 * @param event Event to prevent
 */
export const handlePreventDefault = (event) => {
  event.preventDefault();
};
export const RedirectOnError = (isError, error) => {
  const navigate = useNavigate();

  if (isError) {
    console.error(error);
    navigate(error500Route.toNavigate, { replace: true, state: error });
  }
};

export const getFromEnvFile = (key: string): string => {
  key = `${configuration.security.reactPrefixKey}${key}`;
  const value = process.env[key];
  return value || "";
};

export const moneyFormat = (
  value: number,
  maxDecimals: number = 2,
  minDecimals: number = 0
) => {
  const options = {
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: minDecimals,
    style: "currency",
    currency: "USD",
  };
  return value.toLocaleString("en-US", options);
};

export const getCoverageList = (records, tooltipInfo) => {
  const coverageList = records.map((record) => {
    const { icon, message, order } = record;
    let { value } = record;
    let tooltipText = "";

    if (value.includes("S.B.U")) {
      tooltipText = tooltipInfo.tooltips["S.B.U"];
    } else {
      const clearValue = Number(value.replace(/\s/g, ""));
      value = Number.isNaN(clearValue) ? value : moneyFormat(clearValue);
    }

    return { icon, message, order, value, tooltipText };
  });
  return coverageList;
};

/**
 * Generation of dynamic items for card and benefit
 * according to configuration and information
 * received from services
 * @param limitItemsToShow Items to show in view (JSON config)
 * @param listServiceItems list of items sent by the service
 * @param listLocalItems list of items sent by the JSON local
 * @param variant Element variant to generate (coverage/benefits)
 * @returns
 */
export const generateDynamicItems = (
  limitItemsToShow,
  listServiceItems,
  listLocalItems,
  variant,
  defaultItem
) => {
  const arrayItems = [];
  const sortListServiceItems = listServiceItems.slice();
  const limitLocalItems = listLocalItems.length - 1;

  if (limitItemsToShow > sortListServiceItems.length) {
    console.log(`Se intentan mostrar mas elementos (${variant}) que los
			  configurados en local`);
    return variant === "coverage" ? (
      [defaultItem]
    ) : (
      <Typography variant="body1">
        {parseJsonToHtml(defaultItem.body, optionRemoveRootParrNode)}
      </Typography>
    );
  }
  sortArrayBy(sortListServiceItems, "priority");
  for (let index = 0; index < sortListServiceItems.length; index += 1) {
    if (index > limitItemsToShow) {
      break;
    }
    const indexLocalElement = index > limitLocalItems ? limitLocalItems : index;
    const jsonData = JSON.parse(sortListServiceItems[index].description);
    if (jsonData[0] !== 0) {
      const newItem = {
        id: sortListServiceItems[index].code,
        icon: listLocalItems[indexLocalElement].icon,
        altIcon: listLocalItems[indexLocalElement].altIcon,
        body: getEvaNodeTextByPosition(jsonData, 0, true),
      };
      if (sortListServiceItems[index].isActive) {
        arrayItems.push(newItem);
      }
    }
  }
  return arrayItems;
};

export const getCustomIcon = (icon) => {
  switch (icon) {
    case "female":
      return <FeminineIcon />;
    case "male":
      return <MaleIcon />;
    case "home_health":
      return <HomeHealth />;
    case "maternity":
      return <MaternityIcon />;
    case "no_maternity":
      return <NoMaternityIcon />;

    default:
      return <div>No encontrado</div>;
  }
};

export const hasTitularPassport = (subscription) => {
  return (
    configuration.flowConfiguration.identificationType.passport ===
    subscription.titular.identificationType
  );
};

export const fetchInfoTitularBy = (subscription) => {
  const { incompleteServiceData, isWrongGender } = subscription.titular;

  if (hasTitularPassport(subscription)) {
    return GET_DATA_BY_AGE_AND_GENDER;
  }

  if (incompleteServiceData) {
    return GET_DATA_BY_AGE_AND_GENDER;
  }

  if (isWrongGender && !incompleteServiceData) {
    return GET_DATA_BY_GENDER;
  }

  return GET_DATA_BY_TRACKING_ID;
};

export const noRetriveDataQueryConfig = {
  enabled: false,
  retry: false,
  cacheTime: 0,
};

export const getEndAdornmentLock = () => {
  return {
    endAdornment: (
      <InputAdornment position="end">
        <LockOutlinedIcon />
      </InputAdornment>
    ),
  };
};

export const preventUnsafeActions = {
  onCut: handlePreventDefault,
  onCopy: handlePreventDefault,
  onPaste: handlePreventDefault,
  onContextMenu: handlePreventDefault,
};

export const longDialogProps = (isLoading, progresTimer) => {
  const longProps: { [key: string]: any } = {};

  if (isLoading) {
    longProps.title = SharedInfo.longLoadingDialog.title;
    longProps.subtitle = SharedInfo.longLoadingDialog.subTitle;
    longProps.progresTimer = progresTimer;
  }

  return longProps;
};

// eslint-disable-next-line import/prefer-default-export
export function setValues(setValue: any, jsonData: object) {
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in jsonData) {
    setValue(key, jsonData[key]);
  }
}

export const getJsonElementToText = (
  noFormedData,
  preferentPosition,
  isJson = false
) => {
  let jsonListItemsDescription;
  if (isJson) {
    jsonListItemsDescription = noFormedData;
  } else {
    jsonListItemsDescription = JSON.parse(noFormedData);
  }

  const preferentItem =
    jsonListItemsDescription.length >= 1 &&
    preferentPosition <= jsonListItemsDescription.length - 1
      ? jsonListItemsDescription[preferentPosition]
      : jsonListItemsDescription[0];

  return JSON.stringify(preferentItem);
};
