module.exports = {
  "2xs": "var(--space-2xs)",
  xs: "var(--space-xs)",
  s: "var(--space-s)",
  m: "var(--space-m)",
  l: "var(--space-l)",
  xl: "var(--space-xl)",
  "2xl": "var(--space-2xl)",
  "3xl": "var(--space-3xl)",
  "4xl": "var(--space-4xl)",
  "5xl": "var(--space-5xl)",
  "6xl": "var(--space-6xl)",
  "7xl": "var(--space-7xl)",
  "8xl": "var(--space-8xl)",
  "9xl": "var(--space-9xl)",
  "10xl": "var(--space-10xl)",
  "11xl": "var(--space-11xl)",
  "2xs-xs": "var(--space-2xs-xs)",
  "xs-s": "var(--space-xs-s)",
  "s-m": "var(--space-s-m)",
  "m-l": "var(--space-m-l)",
  "l-xl": "var(--space-l-xl)",
  "xl-2xl": "var( --space-xl-2xl)",
  "2xl-3xl": "var(--space-2xl-3xl)",
  "3xl-4xl": "var(--space-3xl-4xl)",
  "4xl-5xl": "var(--space-4xl-5xl)",
  "5xl-6xl": "var(--space-5xl-6xl)",
  "6xl-7xl": "var(--space-6xl-7xl)",
  "7xl-8xl": "var(--space-7xl-8xl)",
  "8xl-9xl": "var(--space-8xl-9xl)",
  "9xl-10xl": "var(--space-9xl-10xl)",
  "10xl-11xl": "var(--space-10xl-11xl)",
  "2xs-m": "var(--space-2xs-m)",
  "s-l": "var(--space-s-l)",
  "s-5xl": "var(--space-s-5xl)",
};
