import Button, { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";

interface EvaButtonProps
  extends Omit<
    ButtonProps,
    | "className"
    | "fullWidth"
    | "size"
    | "disableRipple"
    | "sx"
    | "TouchRippleProps"
    | "tabIndex"
    | "focusVisibleClassName"
  > {
  children?: any;
  shape?: "square" | "rounded";
  className?: Partial<ClassNameProps>;
}

interface ClassNameProps {
  root: string;
}

function EvaButton({ children, shape, className, ...props }: EvaButtonProps) {
  return (
    <Button
      fullWidth
      disableRipple
      className={clsx(
        "h5 h-auto px-0 py-s leading-fluid",
        className?.root,
        shape && "aspect-square w-auto min-w-0",
        shape === "rounded" && "rounded-full"
      )}
      {...props}
    >
      {children}
    </Button>
  );
}

export default EvaButton;
