function NoMaternityIcon(props) {
  return (
    <svg
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <rect x="0.5" width="46" height="46" rx="23" fill="#E4F2FA" />
      <mask
        id="mask0_942_15493"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="31"
        height="30"
      >
        <rect x="8.5" y="8" width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_942_15493)">
        <path
          d="M15.9999 35.5C15.3124 35.5 14.724 35.2554 14.2349 34.7663C13.7449 34.2763 13.4999 33.6875 13.4999 33C13.4999 32.3125 13.7449 31.7237 14.2349 31.2337C14.724 30.7446 15.3124 30.5 15.9999 30.5C16.6874 30.5 17.2757 30.7446 17.7649 31.2337C18.2549 31.7237 18.4999 32.3125 18.4999 33C18.4999 33.6875 18.2549 34.2763 17.7649 34.7663C17.2757 35.2554 16.6874 35.5 15.9999 35.5ZM29.7499 25.7188L27.2499 23.2188V18.4375L27.4999 18.5312L25.2499 21.1875L23.4686 19.4375L28.7499 13.2188C29.1665 12.7396 29.6353 12.375 30.1561 12.125C30.677 11.875 31.229 11.75 31.8124 11.75C32.979 11.75 33.9686 12.1562 34.7811 12.9688C35.5936 13.7812 35.9999 14.7708 35.9999 15.9375V16.75H33.4999V15.9375C33.4999 15.4583 33.3386 15.0571 33.0161 14.7338C32.6928 14.4113 32.2915 14.25 31.8124 14.25C31.604 14.25 31.4061 14.2917 31.2186 14.375C31.0311 14.4583 30.8645 14.5833 30.7186 14.75L29.7499 15.8438V25.7188ZM27.2499 26.75L28.9999 28.5C28.7707 28.7292 28.5103 28.9112 28.2186 29.0462C27.927 29.1821 27.604 29.25 27.2499 29.25H17.7811C17.2395 29.25 16.8595 29.0104 16.6411 28.5312C16.422 28.0521 16.4895 27.6042 16.8436 27.1875L20.2186 23.2188L10.2499 13.2812L11.9999 11.5L34.9999 34.5L33.2186 36.25L21.9686 25L20.4999 26.75H27.2499ZM28.4999 35.5C27.8124 35.5 27.224 35.2554 26.7349 34.7663C26.2449 34.2763 25.9999 33.6875 25.9999 33C25.9999 32.3125 26.2449 31.7237 26.7349 31.2337C27.224 30.7446 27.8124 30.5 28.4999 30.5C29.1874 30.5 29.7761 30.7446 30.2661 31.2337C30.7553 31.7237 30.9999 32.3125 30.9999 33C30.9999 33.6875 30.7553 34.2763 30.2661 34.7663C29.7761 35.2554 29.1874 35.5 28.4999 35.5ZM22.1249 18.0937L20.3436 16.3125L22.0624 14.3125C21.8957 14.2708 21.7186 14.25 21.5311 14.25H20.9999C20.5832 14.25 20.1824 14.2812 19.7974 14.3437C19.4115 14.4062 19.0207 14.4896 18.6249 14.5938L16.6561 12.625C17.3436 12.3333 18.052 12.1146 18.7811 11.9688C19.5103 11.8229 20.2499 11.75 20.9999 11.75C21.9374 11.75 22.8595 11.8646 23.7661 12.0938C24.672 12.3229 25.5415 12.6667 26.3749 13.125L22.1249 18.0937Z"
          fill="#0057D1"
        />
      </g>
    </svg>
  );
}

export default NoMaternityIcon;
