import { combineReducers, createAction } from "@reduxjs/toolkit";

import catalogSlice from "./slices/catalogSlice";
import dependentsSlice from "./slices/dependentsSlice";
import errorSlice from "./slices/errorSlice";
import payerSlice from "./slices/payerSlice";
import productSlice from "./slices/productSlice";
import quotationSlice from "./slices/quotationSlice";
import saleSlice from "./slices/saleSlice";
import subscriptionSlice from "./slices/subscriptionSlice";

const RESET_STORE_TYPE_NAME = "RESET_STORE";

export const resetStore = createAction(RESET_STORE_TYPE_NAME);

const allReducers = combineReducers({
  catalogInfo: catalogSlice,
  dependentsInfo: dependentsSlice,
  errorInfo: errorSlice,
  payerInfo: payerSlice,
  productInfo: productSlice,
  quotationInfo: quotationSlice,
  saleInfo: saleSlice,
  subscriptionInfo: subscriptionSlice,
});

const rootReducer = (state: ReturnType<typeof allReducers>, action) => {
  if (action.type === RESET_STORE_TYPE_NAME) state = undefined;
  return allReducers(state, action);
};

export default rootReducer;
