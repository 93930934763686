/**
 * array ordering by custom attribute
 * @param array Array to order
 * @param filter attribute used to order
 * @returns New Array order by filter( keyof T)
 */
export function sortArrayBy<T>(array: Array<T>, filter: keyof T): Array<T> {
  if (!array || array.length <= 0) return array;

  return [...array].sort((a, b) => (a[filter] > b[filter] ? 1 : -1));
}

/**
 * check if a variable has data
 * @param data Data to validate
 * @returns true/false
 */
export function hasData<T = {}>(data: string | Array<T>): boolean {
  if (Array.isArray(data)) return data.length > 0;
  const notData = data !== undefined && data !== "" && data !== null;
  return notData;
}

/**
 * Capitalize a word
 * @param word Word to be capitalized
 * @returns Capitalized word
 * @example capitalizeWord("text") // "Text"
 */
export function capitalizeWord(word: string): string {
  if (!hasData(word)) return "";
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * @param sentence Sentence to be capitalized
 * @param {string} [separator=" "] String to separate and join sentence
 * @returns Each word capitalized
 * @example capitalizeSentence("random text") // "Random Text"
 */
export function capitalizeSentence(
  sentence: string,
  separator: string = " "
): string {
  return sentence.trim().split(separator).map(capitalizeWord).join(separator);
}

/**
 * Set dynamic values to a string
 * @param template String to be replace vars
 * @param fields Vars
 * @returns Template with vars in fields
 * @example stringFormat("Hello {0}!", ["EVA"]) // "Hello EVA!"
 */
export function stringFormat<T extends string | number>(
  template: string,
  fields: Array<T>
): string {
  if (!fields.length) return template;

  return fields.reduce(
    (previousValue, field, index) =>
      previousValue.replaceAll(`{${index}}`, `${field}`),
    template
  );
}

export function processFunctions<T>(
  process: Array<(value: T) => T>,
  initialValues: T
): T {
  return process.reduce(
    (previousValue, currentFunction) => currentFunction(previousValue),
    initialValues
  );
}
