import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { EvaError } from "eva";

const errorsAdapter = createEntityAdapter<EvaError>({});

export const initialState = errorsAdapter.getInitialState();

export const errorSlice = createSlice({
  name: "errorInfo",
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<EvaError>) => {
      errorsAdapter.addOne(state, action.payload);
    },
    removeError: (state, action: PayloadAction<EntityId>) => {
      errorsAdapter.removeOne(state, action.payload);
    },
  },
});

export const { addError, removeError } = errorSlice.actions;

export default errorSlice.reducer;
