import configuracion from "app/data/configuration/configuration.json";
import { createServiceGroup } from "app/utils/Service";

const timeoutSubscription = configuracion.services.apiTimeout.subscription;

type SubscriptionServices =
  | "getPreExistingData"
  | "getPreExistencesByTrackingId"
  | "getUserData"
  | "getRucData"
  | "addManyDependent"
  | "createCompleteSubscriptionFromQuotation"
  | "changePayer"
  | "endSubscription";

export default createServiceGroup<SubscriptionServices>({
  services: {
    getPreExistingData: {
      path: "subscription/{0}/integration-confiamed",
    },
    getPreExistencesByTrackingId: {
      path: "subscription/{0}/confiamed-preexistences-by-trackingid",
    },
    getUserData: {
      path: "subscription/person-data-confiamed",
    },
    getRucData: {
      path: "subscription/{0}/company-by-ruc",
    },
    addManyDependent: {
      path: "insured/add-many-dependent",
    },
    createCompleteSubscriptionFromQuotation: {
      path: "subscription/create-complete-from-quotation",
    },
    changePayer: {
      path: "subscription/change-payer",
    },
    endSubscription: {
      path: "subscription/{0}/end-subscription",
    },
  },
  baseUrl: process.env.REACT_APP_SUBSCRIPTION_SERVICE,
  timeout: timeoutSubscription,
});
