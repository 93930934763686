import React from "react";

interface ShowExitPromptProps {
  showExitPrompt?: boolean;
  setShowExitPrompt?: any;
}

const CustomContext = React.createContext<ShowExitPromptProps>({});
export const CustomProvider = CustomContext.Provider;

export default CustomContext;
