import configuration from "app/data/configuration/configuration.json";
import { processFunctions } from "app/utils/Common";
import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { EvaError, EvaResponse } from "eva";

import {
  EvaDataParams,
  EvaQueryParams,
  MergeProcessReturn,
  ProcessFunctions,
} from "./apiService.interface";

export default {
  getData,
  deleteData,
  postData,
  putData,
};

const { networkError, connectionAborted } = configuration.errorsCode;

/**
 * GET request
 * @param url Url to request
 * @param timeout Timeout for request
 * @returns result or exception of request
 */
async function getData<R = any, A = {}>(
  url: string,
  timeout: number,
  headers: AxiosRequestHeaders = {},
  preProcess: ProcessFunctions<EvaQueryParams> = [],
  postProcess: ProcessFunctions<MergeProcessReturn<R, A>> = []
): Promise<MergeProcessReturn<R, A>> {
  try {
    // Process params
    const { processUrl, processTimeout, processHeaders } =
      processFunctions<EvaQueryParams>(preProcess, {
        processUrl: url,
        processTimeout: timeout,
        processHeaders: headers,
      });

    // Execute call
    const response = await axios.get<EvaResponse<R>>(processUrl, {
      timeout: processTimeout,
      headers: processHeaders,
    });

    // Process response
    return processResponse<R, A>(response, postProcess);
  } catch (error) {
    throw processError(error);
  }
}

/**
 * DELETE request
 * @param url Url to request
 * @param timeout Timeout for request
 * @returns result or exception of request
 */
async function deleteData<R = any, A = {}>(
  url: string,
  timeout: number,
  headers: AxiosRequestHeaders = {},
  preProcess: ProcessFunctions<EvaQueryParams> = [],
  postProcess: ProcessFunctions<MergeProcessReturn<R, A>> = []
): Promise<MergeProcessReturn<R, A>> {
  try {
    // Process params
    const { processUrl, processTimeout, processHeaders } =
      processFunctions<EvaQueryParams>(preProcess, {
        processUrl: url,
        processTimeout: timeout,
        processHeaders: headers,
      });

    // Execute call
    const response = await axios.delete<EvaResponse<R>>(processUrl, {
      timeout: processTimeout,
      headers: processHeaders,
    });

    // Process response
    return processResponse<R, A>(response, postProcess);
  } catch (error) {
    throw processError(error);
  }
}

/**
 * POST request
 * @param url Url to request
 * @param data Request Data
 * @param timeout Timeout for request
 * @returns result or exception of request
 */
async function postData<R = any, D = any, A = {}>(
  url: string,
  timeout: number,
  data: D,
  headers: AxiosRequestHeaders = {},
  preProcess: ProcessFunctions<EvaDataParams<D>> = [],
  postProcess: ProcessFunctions<MergeProcessReturn<R, A>> = []
): Promise<MergeProcessReturn<R, A>> {
  try {
    // Process params
    const { processUrl, processData, processTimeout, processHeaders } =
      processFunctions<EvaDataParams<D>>(preProcess, {
        processUrl: url,
        processData: data,
        processTimeout: timeout,
        processHeaders: headers,
      });

    // Execute call
    const response = await axios.post<
      EvaResponse<R>,
      AxiosResponse<EvaResponse<R>>,
      D
    >(processUrl, processData, {
      timeout: processTimeout,
      headers: processHeaders,
    });

    // Process response
    return processResponse<R, A>(response, postProcess);
  } catch (error) {
    throw processError(error);
  }
}

/**
 * PUT request
 * @param {string} url Url to request
 * @param {*} Request Data
 * @param {number} timeout Timeout for request
 * @returns {object} result or exception of request
 */
async function putData<R = any, D = any, A = {}>(
  url: string,
  data: D,
  timeout: number,
  headers: AxiosRequestHeaders = {},
  preProcess: ProcessFunctions<EvaDataParams<D>> = [],
  postProcess: ProcessFunctions<MergeProcessReturn<R, A>> = []
): Promise<MergeProcessReturn<R, A>> {
  try {
    // Process params
    const { processUrl, processData, processTimeout, processHeaders } =
      processFunctions<EvaDataParams<D>>(preProcess, {
        processUrl: url,
        processData: data,
        processTimeout: timeout,
        processHeaders: headers,
      });

    // Execute call
    const response = await axios.put<
      EvaResponse<R>,
      AxiosResponse<EvaResponse<R>>,
      D
    >(processUrl, processData, {
      timeout: processTimeout,
      headers: processHeaders,
    });

    // Process response
    return processResponse<R, A>(response, postProcess);
  } catch (error) {
    throw processError(error);
  }
}

function processResponse<R, A extends {}>(
  response: AxiosResponse<EvaResponse<R>>,
  postProcess: ProcessFunctions<MergeProcessReturn<R, A>> = []
): MergeProcessReturn<R, A> {
  // Set initial values
  const originalResponse = {
    headers: response?.headers,
    response: response?.data,
  } as MergeProcessReturn<R, A>;

  // Loop through each function to process the response
  return processFunctions<MergeProcessReturn<R, A>>(
    postProcess,
    originalResponse
  );
}

function processError(evaError: EvaError): EvaError {
  let error: EvaError = {
    code: "UNDEFINED",
    message: "",
    details: "",
  };

  // When network Error
  if (evaError?.message === networkError.code) {
    error = {
      ...error,
      ...networkError,
    };

    return error;
  }

  // Exists ERROR.CODE
  if (evaError.code) {
    const isConnectionAborted = evaError?.code === connectionAborted.code;

    error = { ...evaError };

    error.message = isConnectionAborted
      ? connectionAborted.message
      : evaError?.message;
  }

  return error;
}
