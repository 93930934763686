import DOMPurify from "dompurify";
import { EvaNode } from "eva";
import parse, { HTMLReactParserOptions } from "html-react-parser";

export const parseTextToHtml = (
  html: string,
  options: HTMLReactParserOptions | undefined = undefined,
  withSanitize = true
) => {
  if (withSanitize) html = DOMPurify.sanitize(html);

  return options ? parse(html, options) : parse(html);
};

/**
 * Convert HTML Text to JSX.Element to render
 * @param noFormedData EvaNode in string
 * @param options Object for customize parse
 * @param formed Default True, Indicator if is EvaNode Text (True) or HTML Text (False)
 * @param withSanitize Default True, provide clear attributes, False return all attributes
 * @returns String | JSX.Element | Array<JSX.Element>
 */
export function parseJsonToHtml(
  noFormedData: string,
  options: HTMLReactParserOptions = undefined,
  formed = true,
  withSanitize = true
): string | JSX.Element | JSX.Element[] {
  if (!noFormedData) {
    return null;
  }
  const htmlOnJson = formed
    ? (JSON.parse(noFormedData) as EvaNode)
    : noFormedData;

  let htmlConverted = parseJsonStructureToHtml(htmlOnJson);
  if (withSanitize) htmlConverted = DOMPurify.sanitize(htmlConverted);

  return options ? parse(htmlConverted, options) : parse(htmlConverted);
}

/**
 * - Build an html structure of elements with the text (if node.Text exists) and the children's (if length of node.Children is greater than 0)
 * - It is executed recursively to obtain all the child elements with their respective attributes and tags.
 * @param node Estándar Node estructure
 * @returns String html to parse
 */
export function parseJsonStructureToHtml(node: EvaNode | string): string {
  if (typeof node === "string") return node;

  let html: string = "";

  const isValidCurrentTag = isValidTag(node.Tag);

  // Insert open TAG
  if (isValidCurrentTag) {
    html += `<${node.Tag} ${getAttrsElement(node.Attr)}>`;
  }

  // Insert inner Text
  if (isValidCurrentTag && !!node?.Text) {
    html += node.Text;
  }

  if (!!node.Children.length && node.Children.length > 0) {
    // Get children HTML
    html += node.Children.reduce(
      (accumulate, childrenNode) =>
        accumulate + parseJsonStructureToHtml(childrenNode),
      ""
    );
  }

  // Insert close TAG
  if (isValidCurrentTag) {
    html += `</${node.Tag}>`;
  }

  return html;
}

/**
 * Get EvaNode by position if noFormedData is Array
 * @param noFormedData Array<EvaNode> in text
 * @param preferredPosition Position to select
 * @returns EvaNode in text by preferredPosition
 */
export function getEvaNodeTextByPosition(
  noFormedData: string,
  preferredPosition: number,
  isJson: boolean = false
): string {
  const jsonListItemsDescription = isJson
    ? noFormedData
    : (JSON.parse(noFormedData) as Array<EvaNode>);

  if (!Array.isArray(jsonListItemsDescription)) return noFormedData;
  const noFormedDataSize = jsonListItemsDescription.length;
  const isValidPosition =
    noFormedDataSize >= 1 && preferredPosition <= noFormedDataSize;

  const preferredItem = isValidPosition
    ? jsonListItemsDescription[preferredPosition]
    : jsonListItemsDescription;

  return JSON.stringify(preferredItem);
}

export function parseJsonToText(noFormedData: string, formed: boolean = true) {
  const htmlOnJson: EvaNode = formed ? JSON.parse(noFormedData) : noFormedData;
  return parseJsonStructureToText(htmlOnJson);
}

export function parseJsonStructureToText(node: EvaNode): string {
  let html: string = "";
  if (node.Children.length > 0) {
    node.Children.forEach((element) => {
      html += `${parseJsonStructureToText(element)}`;
    });
  } else if (isValidTag(node.Tag)) {
    html += `${node.Text} `;
  }
  return html;
}

/**
 * Return attributes
 * @param node Element html
 * @returns Attrs
 */
const getAttrsElement = (nodeAttr: EvaNode["Attr"]) =>
  Object.keys(nodeAttr).reduce((accumulate, current) => {
    if (["className", "class"].includes(current))
      return `${accumulate} class="${nodeAttr[current]}"`;

    return `${accumulate} ${current}="${nodeAttr[current]}"`;
  }, "");

const EXCLUDE_TAGS: Array<keyof HTMLElementTagNameMap> = [
  "html",
  "body",
  "head",
];

/**
 * @param tag valid DOM tag
 * @returns boolean = tag param exists and not in EXCLUDE_TAGS
 */
const isValidTag = (tag: keyof HTMLElementTagNameMap | null): boolean =>
  tag && !EXCLUDE_TAGS.includes(tag);
