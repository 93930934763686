import { formatISO } from "date-fns";
import moment from "moment";

/**
 * Age to BirthDate (with current Date)
 * @param age number age
 * @returns yyyy/mm/dd  birthDate
 */
export function ageToBirthDate(age: number) {
  let birthDate = "";
  const currentDate = new Date();
  const months = 12 * age;
  currentDate.setMonth(currentDate.getMonth() - months);
  const day =
    currentDate.getDate() < 10
      ? `0${currentDate.getDate()}`
      : currentDate.getDate();
  const month =
    currentDate.getMonth() + 1 < 10
      ? `0${currentDate.getMonth() + 1}`
      : currentDate.getMonth() + 1;
  birthDate = `${currentDate.getFullYear()}/${month}/${day}`;
  return birthDate;
}

export function currentDateMinusAge(age) {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - age);
  return new Date(currentDate);
}

/**
 * Calculate age by date of birth
 * @param dateString Date of birth
 * @returns Age
 */
export function getAge(dateString) {
  const birthday = moment(dateString, "DD/MM/YYYY").toDate();
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliSeconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function formatYearMonthDay(date) {
  return moment(date).format("YYYY/MM/DD");
}

export function dateToISO(date, reverse = false, separator = "-") {
  const format = formatISO(date, { representation: "date" }).replaceAll(
    "-",
    separator
  );

  return reverse ? format.split(separator).reverse().join(separator) : format;
}

export function reverseStringDate(date) {
  return date.split("/").reverse().join("/");
}
