import { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

/**
 * Const to remove space between paragraphs by
 * replacing element P with span
 */
export const optionRemoveRootParrNode: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.attribs) {
      const { name, children, attribs } = domNode;
      if (name === "p") {
        return <span className={attribs.class}>{domToReact(children)}</span>;
      }
    }
    return null;
  },
};

export default { optionRemoveRootParrNode };
