import { hashHmacSHA256, hashSHA256 } from "app/utils";

import { EvaDataParams } from "../axios/apiService.interface";

export default function injectSignatureHeaders(keySecret: string = null) {
  return <D>(params: EvaDataParams<D>): EvaDataParams<D> => {
    const stringToHash = JSON.stringify(params.processData);

    const stringHashed = !keySecret
      ? hashSHA256(stringToHash)
      : hashHmacSHA256({
          data: stringToHash,
          key: keySecret,
        });

    const processHeaders = {
      ...params.processHeaders,
      "X-Signature": stringHashed,
    };

    return {
      ...params,
      processHeaders,
    };
  };
}

export function injectSignatureHeadersForEndSale(params) {
  const keySecret = process.env.REACT_APP_ENCRYPT_PUBLIC_KEY_512;
  const dataToSign = params.processData;

  const stringToHash = JSON.stringify(dataToSign);

  const stringHashed = !keySecret
    ? hashSHA256(stringToHash)
    : hashHmacSHA256({
        data: stringToHash,
        key: keySecret,
      });

  const processHeaders = {
    ...params.processHeaders,
    "X-Signature": stringHashed,
  };

  return {
    ...params,
    processHeaders,
  };
}

export function injectSignatureHeadersForRegisterSale(params) {
  const keySecret =
    params.processData.trackingId +
    params.processData.data.refundAccount.ownerIdentification;
  const dataToSign = params.processData.data;
  const stringToHash = JSON.stringify(dataToSign.refundAccount);
  const stringHashed = !keySecret
    ? hashSHA256(stringToHash)
    : hashHmacSHA256({
        data: stringToHash,
        key: keySecret,
      });

  const processHeaders = {
    ...params.processHeaders,
    "X-Signature": stringHashed,
  };

  return {
    ...params,
    processHeaders,
  };
}

export const injectSignatureHeader = (
  processData: any,
  keySecret: string = null
) => {
  const stringToHash = JSON.stringify(processData);

  const stringHashed = keySecret
    ? hashHmacSHA256({
        data: stringToHash,
        key: keySecret,
      })
    : hashSHA256(stringToHash);

  return {
    "X-Signature": stringHashed,
  };
};
