import groupConfiguration from "./groupConfiguration";
import groupFlowManager from "./groupFlowManager";
import groupProduct from "./groupProduct";
import groupQuotation from "./groupQuotation";
import groupSale from "./groupSale";
import groupSubscription from "./groupSubscription";
import groupIntegration from "./integrationServce";

export default {
  configuration: groupConfiguration,
  product: groupProduct,
  quotation: groupQuotation,
  subscription: groupSubscription,
  sale: groupSale,
  flowManager: groupFlowManager,
  integration: groupIntegration,
};
