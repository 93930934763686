import { Typography } from "eva-lib";

import { BenefitCardProps } from "./BenefitCard.interface";

const location = process.env.PUBLIC_URL;

function BenefitCard(props: BenefitCardProps) {
  const { icon, title, body } = props;

  return (
    <div className="flex flex-col items-center text-center">
      <div className="mx-auto mb-m flex h-6xl w-6xl items-center justify-center rounded-full bg-primary-alternative1-50">
        <img src={location + icon} alt={title} className="" />
      </div>
      <Typography
        variant="body1"
        component="span"
        className={{ root: "h5 mb-xs font-normal text-primary-400" }}
      >
        {title}
      </Typography>
      <div className="body1 text-neutral-900">{body}</div>
    </div>
  );
}

export default BenefitCard;
