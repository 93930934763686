import { ReactNode } from "react";
import Accordion, { AccordionProps } from "@mui/material/Accordion";

// root: personalize all component style with TW classes ex: text-primary-alternative1-600, bg-secondary-50 border
interface ClassNameProps {
  root: string;
}

interface EvaAccordionProps extends Omit<AccordionProps, "className"> {
  children: ReactNode | ReactNode[];
  className?: Partial<ClassNameProps>;
}

export default function EvaAccordion({
  className,
  children,
  ...props
}: EvaAccordionProps) {
  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      classes={{ root: className?.root }}
      {...props}
    >
      {children}
    </Accordion>
  );
}
