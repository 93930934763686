import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EvaContainer from "@eva/EvaContainer/index";
import BenefitCard from "@health/BenefitCard";
import CarouselCards from "@health/CarouselCards";
import { CarouselCardsProps } from "@health/CarouselCards/CarouselCards";
import HomeInfo from "app/data/containers/home/page.json";
import { productDetailRoute } from "app/routes";
import {
  getEvaNodeTextByPosition,
  parseJsonToHtml,
} from "app/utils/JsonConverter";
import { Button, TypographyFormat } from "eva-lib";
import configFluidSpacing from "styles/config-fluid-spacing";

type CarouselBenefitsProps = Omit<
  CarouselCardsProps,
  "children" | "renderNotOverflow"
>;
type benefitProps = {
  name: string;
  code: string;
  priority: number;
  planType: any;
  description: string;
  isActive: boolean;
};

const benefitsInfo = HomeInfo.benefitsSection;
const defaultIcon = benefitsInfo.itemsList.find(
  (item) => item.code === "default"
);

const getIconByCode = (code: string): string => {
  const benefit = benefitsInfo.itemsList.find((item) => item.code === code);
  if (benefit) {
    return benefit.icon;
  }
  return defaultIcon.icon;
};

function BenefitsSection() {
  const navigate = useNavigate();
  // @ts-ignore
  const { product } = useSelector((store) => store.productInfo);

  let benefits: benefitProps[] = product ? [...product.benefits] : [];

  benefits = benefits.filter((benefit) => {
    const jsonData = JSON.parse(benefit.description);
    if (jsonData[0] === 0) return false;
    return benefit;
  });

  const carouselBenefitsProps: CarouselBenefitsProps = useMemo(
    () => ({
      initialSlide: Math.ceil(benefits.length / 2) - 1,
      sizeDefault: {
        width: 220,
        spacing: configFluidSpacing.m,
      },
      sizeBreakpoints: {
        sm: {
          width: 220,
          spacing: configFluidSpacing.m,
        },
        lg: {
          width: 160,
          spacing: configFluidSpacing.m,
        },
      },
    }),
    [benefits.length]
  );
  const mapCards = benefits.map((benefit) => {
    const options = undefined;
    const formed = true;
    const capitalizeContent = false;
    return {
      key: `benefit-card-${benefit.code}`,
      component: (
        <BenefitCard
          key={`benefit-card-${benefit.code}`}
          icon={getIconByCode(benefit.code)}
          title={benefit.name}
          body={parseJsonToHtml(
            getEvaNodeTextByPosition(benefit.description, 0),
            options,
            formed,
            capitalizeContent
          )}
        />
      ),
    };
  });

  const handleNavigate = useCallback(() => {
    navigate(productDetailRoute.toNavigate);
  }, []);

  return (
    <section className="pb-2xl pt-2xl">
      <EvaContainer className={{ root: "mx-auto box-border text-center" }}>
        <div>
          <TypographyFormat
            variant="h3"
            content={HomeInfo.benefitsSection.title}
            className={{ root: "mb-l text-center font-semibold" }}
          />
        </div>
        <div className="-mx-s-5xl grid grid-cols-12 gap-x-s-l md:mx-0">
          <div className="col-span-12">
            {product && (
              <CarouselCards
                {...carouselBenefitsProps}
                renderNotOverflow={
                  <div className="-mt-m -ml-m flex justify-center">
                    {mapCards.map((card) => {
                      return (
                        <div
                          key={card.key}
                          className="flex flex-1 justify-center pt-m pl-m"
                        >
                          {card.component}
                        </div>
                      );
                    })}
                  </div>
                }
              >
                {mapCards}
              </CarouselCards>
            )}
          </div>
        </div>
        <div className="flex justify-center">
          {HomeInfo.benefitsSection.buttonText && (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={{
                root: "body1 mt-m py-s font-semibold md:block md:w-1/3",
              }}
              data-testid="button-detail-product"
              onClick={handleNavigate}
            >
              {HomeInfo.benefitsSection.buttonText}
            </Button>
          )}
        </div>
      </EvaContainer>
    </section>
  );
}

export default memo(BenefitsSection);
