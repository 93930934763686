import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: null,
  total: null,
  reference: null,
  paymentData: null,
  paymentPeriodicityCode: null,
  paymentState: null,
  contractNumber: null,
  refundAccount: {
    identificationType: null,
    identification: null,
    firstName: null,
    lastName: null,
    accountType: null,
    accountNumber: null,
    bankSelect: null,
  },
  billingInformation: {
    isChangeBiller: null,
    billerIdType: null,
    idNumber: null,
    rucNumber: null,
    rucType: null,
    businessName: null,
    firstName: null,
    lastName: null,
    emailBiller: null,
    phoneNumberBiller: null,
  },
};

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    setPaymentPeriodicityCode: (state, action) => {
      state.paymentPeriodicityCode = action.payload;
    },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
    },
    setRefundAccount: (state, action) => {
      state.refundAccount = action.payload;
    },
    setChangeBiller: (state, action) => {
      state.billingInformation.isChangeBiller = action.payload;
    },
    setBillerInformation: (state, action) => {
      state.billingInformation = action.payload;
    },
    setContract: (state, action) => {
      state.paymentState = action.payload;
    },
    updatePaymentData: (state, action) => {
      state.url = action.payload.url;
      state.total = action.payload.total;
      state.reference = action.payload.reference;
      state.contractNumber = action.payload.contractNumber;
    },
  },
});

export const {
  setPaymentPeriodicityCode,
  setPaymentData,
  setRefundAccount,
  setChangeBiller,
  setBillerInformation,
  setContract,
  updatePaymentData,
} = saleSlice.actions;

export default saleSlice.reducer;
