import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";

import "swiper/css/bundle";
import CustomDots from "./CustomDots";

export interface CardSize {
  width: number;
  spacing: string;
}

export interface CarouselCardSize {
  xs?: CardSize;
  sm?: CardSize;
  md?: CardSize;
  lg?: CardSize;
  xl?: CardSize;
}

export interface CarouselCardsProps {
  children:
    | { key: string; component: JSX.Element }[]
    | { key: number; component: JSX.Element }[];
  sizeBreakpoints: CarouselCardSize;
  sizeDefault: CardSize;
  initialSlide?: number;
  groupSlides?: number;
  renderNotOverflow?: JSX.Element;
}

function CarouselCards(props: CarouselCardsProps) {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("tw-sm"));
  const matchMd = useMediaQuery(theme.breakpoints.up("tw-md"));
  const matchLg = useMediaQuery(theme.breakpoints.up("tw-lg"));
  const matchXl = useMediaQuery(theme.breakpoints.up("tw-xl"));

  const {
    children,
    sizeDefault,
    sizeBreakpoints,
    initialSlide = 0,
    groupSlides = 1,
    renderNotOverflow,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenElementRef = useRef<HTMLDivElement>(null);

  const buildSizes = useMemo<CarouselCardSize>(() => {
    const xs = sizeBreakpoints?.xs ?? sizeDefault;
    const sm = sizeBreakpoints?.sm ?? xs;
    const md = sizeBreakpoints?.md ?? sm;
    const lg = sizeBreakpoints?.lg ?? md;
    const xl = sizeBreakpoints?.xl ?? lg;

    return { xs, sm, md, lg, xl };
  }, [sizeBreakpoints, sizeDefault]);

  const childrenArray = Array.isArray(children) ? children : [children];

  const [slideIndex, setSlideIndex] = useState(() => initialSlide);
  const [sizes, setSizes] = useState({
    containerSize: 0,
    itemsCalcSize: 0,
  });

  const currentBreakpoint = useMemo(() => {
    if (matchXl) return "xl";
    if (matchLg) return "lg";
    if (matchMd) return "md";
    if (matchSm) return "sm";
    return "xs";
  }, [matchLg, matchMd, matchSm, matchXl]);

  const { width: widthRender, spacing: spacingRender } = useMemo(
    () => buildSizes[currentBreakpoint],
    [buildSizes, currentBreakpoint]
  );

  const isOverflow = useMemo(
    () => sizes.containerSize < sizes.itemsCalcSize,
    [sizes]
  );

  const HandleChangeIndex = useCallback((index: number) => {
    setSlideIndex(index);
  }, []);

  const HandleSlideChange = useCallback(
    (swiperEvent: SwiperType) => {
      HandleChangeIndex(swiperEvent.realIndex);
    },
    [HandleChangeIndex]
  );

  const handleOnInit = useCallback(
    (swiperEvent: SwiperType) => {
      setTimeout(() => {
        if (swiperEvent.activeIndex !== initialSlide) {
          swiperEvent.slideTo(initialSlide, 100);
        }
      }, 200);
    },
    [initialSlide]
  );

  const ListenerResize = useCallback(
    (swiperEvent: SwiperType) => {
      swiperEvent.$wrapperEl[isOverflow ? "addClass" : "removeClass"](
        "!cursor-default"
      );
    },
    [isOverflow]
  );

  useEffect(() => {
    const listener = () => {
      const innerWidth = containerRef.current.getBoundingClientRect().width;
      const spaceFluidNumber = getComputedStyle(hiddenElementRef.current).width;
      const parseSpaceFluid = parseFloat(spaceFluidNumber);

      const calcItemsSizes =
        (widthRender + parseSpaceFluid) * childrenArray.length -
        parseSpaceFluid;

      setSizes({
        containerSize: innerWidth,
        itemsCalcSize: calcItemsSizes,
      });
    };

    listener();

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [childrenArray.length, widthRender]);

  return (
    <div className="w-full" ref={containerRef}>
      <div
        className="hidden"
        style={{ width: spacingRender }}
        ref={hiddenElementRef}
      />
      {!!renderNotOverflow && !isOverflow ? (
        renderNotOverflow
      ) : (
        <Swiper
          className="-mt-24 pt-24"
          style={{
            maxWidth: `calc(${childrenArray.length} * calc(${widthRender}px + ${spacingRender}))`,
          }}
          slidesPerView="auto"
          slidesPerGroup={groupSlides}
          centeredSlides={isOverflow}
          grabCursor={isOverflow}
          simulateTouch={isOverflow}
          cssMode={false}
          autoHeight={false}
          initialSlide={initialSlide}
          onSlideChange={HandleSlideChange}
          onResize={ListenerResize}
          onInit={handleOnInit}
          onSlidesLengthChange={handleOnInit}
          resistance
        >
          {/* Slides */}
          {childrenArray.map((slide) => {
            return (
              <SwiperSlide
                key={slide.key}
                className="!h-auto"
                style={{
                  width: `${widthRender}px`,
                  padding: `0 calc(${spacingRender}/ 2)`,
                }}
              >
                {slide.component}
              </SwiperSlide>
            );
          })}

          {/* PAGINATION */}
          {isOverflow && (
            <CustomDots slideIndex={slideIndex} onClick={HandleChangeIndex} />
          )}
        </Swiper>
      )}
    </div>
  );
}

export default CarouselCards;
