import { Suspense } from "react";
import { Outlet } from "react-router-dom";
// Todo eliminar a posterior
import EvaHeader from "@eva/EvaHeader";
import LoadingDialog from "@health/LoadingDialog";
import ProtectRoute from "@health/ProtectRoute";
// @ts-ignore
import FooterLogo from "app/assets/Footer/logo-eva.svg";
// @ts-ignore
import HeaderLogo from "app/assets/Header/logo-dark.svg";
import { CustomProvider } from "app/components/CustomContext";
import SharedInfo from "app/data/configuration/shared.json";
import useExitPrompt from "app/hooks/flow/useExitPrompt";
import { homeRoute } from "app/routes/index";
import { Footer } from "eva-lib";

function HealthLayout() {
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);
  return (
    <ProtectRoute>
      <CustomProvider value={{ showExitPrompt, setShowExitPrompt }}>
        <EvaHeader
          logo={{
            url: homeRoute,
            src: HeaderLogo,
            alt: "string",
          }}
        />
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 999999,
                pointerEvents: "none",
              }}
            >
              <LoadingDialog open />
            </div>
          }
        >
          <Outlet />
        </Suspense>
        <Footer
          platformLogo={{
            alt: SharedInfo.footerSeccion.platformLogo.alt,
            src: FooterLogo,
          }}
          rightsReserved={SharedInfo.footerSeccion.rightsReserved}
          platformInfo={SharedInfo.footerSeccion.platformInfo}
          className={{
            root: "bg-primary-alternative1-50",
            rightsReserved: "!font-normal",
            platformInfo: "!font-normal",
          }}
        />
      </CustomProvider>
    </ProtectRoute>
  );
}

export default HealthLayout;
