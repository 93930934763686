import Box, { BoxProps } from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { SpinnerProps } from "./Spinner.interface";

/**
 * Spinner for EVA
 *
 * @param props.className determines the main color of the spinner,Ex: color="spinner-primary-color" Classname for
 *  for the primary color.
 * @param props.thickness determines the thickness width of the spinner which in an SVG starts from 1 to infinite
 * @param props.size  Give the width and height of the Spinner size="100" Equals, 100px width X 100px height.
 * Examples of the implementation at Examples.tsx
 * Documentation at: https://v4.mui.com/components/progress/#circular MUI v.4
 *
 */

function Spinner(props: SpinnerProps) {
  const CustomBox = styled(Box)<BoxProps>(() => ({
    root: {
      width: props.size,
      height: `calc(${props.size}px + 10px)`,
    },
  }));

  return (
    <CustomBox className={clsx("translate-x-[1%]", props.className)}>
      <CircularProgress
        variant="determinate"
        size={props.size}
        thickness={props.thickness}
        value={100}
      />

      <CircularProgress
        variant="indeterminate"
        disableShrink
        className="absolute left-0"
        sx={{
          animationDuration: "550ms",
          circle: {
            strokeLinecap: "round",
          },
        }}
        size={props.size}
        thickness={props.thickness}
      />
    </CustomBox>
  );
}

export default Spinner;
