import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EvaHeader from "@eva/EvaHeader";
import LoadingDialog from "@health/LoadingDialog";
import { useQuery } from "@tanstack/react-query";
// @ts-ignore
import FooterLogo from "app/assets/Footer/logo-eva.svg";
// @ts-ignore
import HeaderLogo from "app/assets/Header/logo-dark.svg";
import BenefitsSection from "app/containers/Home/BenefitsSection";
import CoverageSecction from "app/containers/Home/CoverageSection";
import FaQSection from "app/containers/Home/FaqSection";
import HeroSection from "app/containers/Home/HeroSection";
import SponsorSection from "app/containers/Home/SponsorSection";
import sponsorInfo from "app/data/configuration/brand/sponsorInfo.json";
import configuration from "app/data/configuration/configuration.json";
import SharedInfo from "app/data/configuration/shared.json";
import { homeRoute, requestQuotationDataRoute } from "app/routes/index";
import QuotationService from "app/services/backend/quotationService";
import { setCategories, setProduct } from "app/store/slices/productSlice";
import { RedirectOnError } from "app/utils";
import { Footer } from "eva-lib";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "RESET_STORE" });
  }, []);

  const navigateToNext = useCallback(() => {
    navigate(requestQuotationDataRoute.toNavigate);
  }, []);

  const fetchProductData = async () => {
    const responseData = await QuotationService.getProductByAdvanceFiltersCodes(
      sponsorInfo
    );

    const response = responseData.response.result.find(
      (item) => item.code === sponsorInfo.productCode
    );

    // @ts-ignore
    dispatch(setProduct(response));
    dispatch(setCategories(configuration.services.catalogInfo.limitDependents));
    return response;
  };

  const {
    data: product,
    isLoading: isLoadingProduct,
    isError: isErrorProduct,
    error: errorProduct,
  } = useQuery(["products"], fetchProductData);

  RedirectOnError(isErrorProduct, errorProduct);

  const handleFaQ = useCallback(() => {
    console.log("cta");
  }, []);

  return (
    <>
      <EvaHeader
        logo={{
          url: homeRoute,
          src: HeaderLogo,
          alt: "string",
        }}
      />
      {/** HERO SECCION */}
      <HeroSection navigateToNext={navigateToNext} />

      {/** SPONSOR SECCION */}
      <SponsorSection navigateToNext={navigateToNext} />

      {/** COVERAGE SECCION */}
      <CoverageSecction product={product} />

      {/** BENEFITS SECCION */}
      <BenefitsSection />

      <FaQSection onClick={handleFaQ} />

      <Footer
        platformLogo={{
          alt: SharedInfo.footerSeccion.platformLogo.alt,
          src: FooterLogo,
        }}
        rightsReserved={SharedInfo.footerSeccion.rightsReserved}
        platformInfo={SharedInfo.footerSeccion.platformInfo}
        className={{
          root: "bg-primary-alternative1-50",
          rightsReserved: "!font-normal",
          platformInfo: "!font-normal",
        }}
      />
      <LoadingDialog
        open={isLoadingProduct}
        title={SharedInfo.longLoadingDialog.title}
        subtitle={SharedInfo.longLoadingDialog.subTitle}
        progresTimer={configuration.services.apiTimeout.quotation}
      />
    </>
  );
}

export default Home;
