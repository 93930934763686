import { createServiceGroup } from "app/utils/Service";

type FlowManagerServices = "agreeTermsAndConditions";

export default createServiceGroup<FlowManagerServices>({
  services: {
    agreeTermsAndConditions: {
      path: "/tracking/{0}/acceptance-log",
      query: [],
    },
  },
  baseUrl: process.env.REACT_APP_FLOW_MANAGER_SERVICE,
  timeout: 30000,
});
