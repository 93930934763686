import { useState } from "react";
import DualButton, { DualButtonProps } from "@health/DualButton";
import SponsorSectionContent from "@health/SponsorSectionContent";
import HomeInfo from "app/data/containers/home/page.json";
import { Button, Container } from "eva-lib";

const heroInfo = HomeInfo.heroSection;
const sponsorInfo = HomeInfo.sponsorSection;

function HealthSponsorSectionVariant(props) {
  const { variant } = sponsorInfo;
  const { activeButton } = props;

  const customSponsorInfo = {
    ...sponsorInfo,
    sponsorImagList:
      activeButton === "primary"
        ? sponsorInfo.sponsorImagList
        : sponsorInfo.sponsorImagListAlternative,
  };

  if (variant === "initial") {
    return (
      <SponsorSectionContent
        sponsorInfo={customSponsorInfo}
        className={{
          divider: {
            text: "text-primary-500",
          },
          description: "font-normal",
        }}
      />
    );
  }
  if (variant === "onlyLogos") {
    return (
      <SponsorSectionContent sponsorInfo={customSponsorInfo} hideDescription />
    );
  }
}

function SponsorSection(props) {
  const [activeButton, setActiveButton] = useState("primary");

  const primaryButton = {
    label: "ConfiRed1",
    onClick: () => setActiveButton("primary"),
  };

  const secondaryButton = {
    label: "ConfiRed2",
    onClick: () => setActiveButton("secondary"),
  };

  return (
    <section className="pb-2xl">
      <Container>
        <Button
          className={{ root: "body1 mb-m hidden" }}
          type="submit"
          variant="contained"
          onClick={() => props.navigateToNext()}
        >
          {heroInfo.buttonText}
        </Button>
      </Container>
      <div className="container mx-auto box-border px-s-m pb-s">
        <HealthSponsorSectionVariant activeButton={activeButton} />
      </div>
      <div className="mx-auto mb-l w-9/12 md:w-4/12 lg:w-3/12 ">
        <DualButton
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          active={activeButton as DualButtonProps["active"]}
        />
      </div>
    </section>
  );
}

export default SponsorSection;
