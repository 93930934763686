import configuration from "app/data/configuration/configuration.json";
import { encryptRSA, getFromEnvFile } from "app/utils";

export default function injectKeyToParameters(params) {
  const { processData } = params;
  const { data, propsToKey } = processData;
  const keyToEncrypt = propsToKey.map((prop) => prop).join("|");

  const publicKey = getFromEnvFile(
    configuration.security.encryptionVariables.ENCRYPT_PUBLIC_KEY_1024
  );

  params.processData = {
    key: encryptRSA(keyToEncrypt, publicKey) || "",
    ...data,
  };

  return {
    ...params,
  };
}
