import configuracion from "app/data/configuration/configuration.json";
import { createServiceGroup } from "app/utils/Service";

const timeoutConfiguration = configuracion.services.apiTimeout.configuration;

type ConfigurationServices =
  | "getPortal"
  | "getPortalById"
  | "getPortalByCode"
  | "getTyCByCode";

export default createServiceGroup<ConfigurationServices>({
  services: {
    getPortal: {
      path: "portal",
    },
    getPortalById: {
      path: "portal/{0}",
      query: [["includeItems", "true"]],
    },
    getPortalByCode: {
      path: "portal/{0}/by-code",
      query: [["includeItems", "true"]],
      timeout: 30000,
    },
    getTyCByCode: {
      path: "term-and-condition/by-code/{0}",
    },
  },
  timeout: timeoutConfiguration,
  baseUrl: process.env.REACT_APP_CONFIGURATION_SERVICE,
});
