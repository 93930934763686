import { createTheme, ThemeOptions } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { Shadows } from "@mui/material/styles/shadows";
import colors from "styles/config-colors";
import fluidSpacing from "styles/config-fluid-spacing";

const { neutral, white, primary, secondary, info, success, warning, error } =
  colors;
const initialAlternative = "500";

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      "tw-sm": 480,
      "tw-md": 768,
      "tw-lg": 1280,
      "tw-xl": 1920,
    },
  },
  palette: {
    primary: {
      main: primary[initialAlternative],
      dark: primary[initialAlternative],
    },
    secondary: {
      main: secondary.alternative1[initialAlternative],
      dark: primary[200],
      contrastText: secondary.alternative4[900],
    },
    error: {
      main: error[initialAlternative],
    },
    action: {
      disabledBackground: neutral[100],
      disabled: neutral[500],
    },
    complementary: {
      main: primary.alternative1[500],
      contrastText: white,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ["Rubik", "Arial", "Helvetica", "sans-serif"].join(","),
  },
  shadows: Array(25).fill("none") as Shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          textTransform: "none",
          borderWidth: "2px !important",
          ":hover": {
            borderWidth: "2px ",
          },
        },
        startIcon: {
          margin: 0,
        },
        containedPrimary: {
          backgroundColor: colors.primary.alternative3["400"],
          color: colors.white,
          borderColor: colors.primary.alternative3["400"],
          ":hover": {
            backgroundColor: colors.primary.alternative3["200"],
            color: colors.white,
          },
          ":active": {
            backgroundColor: colors.primary.alternative3["600"],
          },
          ":disabled": {
            borderColor: colors.neutral["500"],
            backgroundColor: colors.neutral["50"],
          },
        },
        outlinedPrimary: {
          backgroundColor: colors.transparent,
          color: colors.primary["500"],
          borderColor: colors.primary["500"],
          ":hover": {
            backgroundColor: colors.transparent,
            color: colors.primary["300"],
            borderColor: colors.primary["300"],
          },
          ":active": {
            backgroundColor: colors.transparent,
            color: colors.primary["500"],
            borderColor: colors.primary["500"],
          },
          ":disabled": {
            borderColor: colors.neutral["500"],
            backgroundColor: colors.neutral["50"],
          },
        },
        textPrimary: {
          color: colors.primary["400"],
          backgroundColor: colors.transparent,
          ":hover": {
            backgroundColor: colors.transparent,
            color: colors.primary["300"],
          },
          ":active": {
            backgroundColor: colors.transparent,
            color: colors.primary["500"],
          },
          ":disabled": {
            backgroundColor: colors.transparent,
            color: colors.neutral["400"],
          },
        },
        containedSecondary: {
          backgroundColor: colors.primary.alternative1["50"],
          color: colors.primary.alternative3["400"],
          borderColor: colors.primary.alternative1["50"],
          ":hover": {
            backgroundColor: colors.primary.alternative1["50"],
            color: colors.primary.alternative3["200"],
          },
          ":active": {
            backgroundColor: colors.primary.alternative1["200"],
            color: colors.primary.alternative3["300"],
          },
          ":disabled": {
            borderColor: colors.neutral["500"],
            backgroundColor: colors.neutral["50"],
          },
        },
        outlinedSecondary: {
          backgroundColor: colors.transparent,
          color: colors.secondary["400"],
          borderColor: colors.secondary["500"],
          ":hover": {
            backgroundColor: colors.transparent,
            color: colors.secondary["300"],
            borderColor: colors.secondary["300"],
          },
          ":active": {
            backgroundColor: colors.transparent,
            color: colors.secondary["500"],
            borderColor: colors.secondary["500"],
          },
          ":disabled": {
            borderColor: colors.neutral["500"],
            backgroundColor: colors.neutral["50"],
          },
        },
        textSecondary: {
          color: colors.secondary["400"],
          backgroundColor: colors.transparent,
          ":hover": {
            backgroundColor: colors.transparent,
            color: colors.secondary["300"],
          },
          ":active": {
            backgroundColor: colors.transparent,
            color: colors.secondary["500"],
          },
          ":disabled": {
            backgroundColor: colors.transparent,
            color: colors.neutral["400"],
          },
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "complementary" },
          style: {
            backgroundColor: primary.alternative1[500],
            color: colors.white,
            border: "1.5px solid",
            borderColor: primary.alternative1[500],
            ":hover": {
              backgroundColor: primary.alternative1[600],
            },
            ":active": {
              backgroundColor: primary.alternative1[900],
            },
            "&.Mui-disabled": {
              backgroundColor: `${primary.alternative1["200"]} !important`,
              color: `${primary.alternative1[100]} !important`,
            },
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.neutral[initialAlternative]}`,
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: 0,
          backgroundColor: colors.neutral.alternative1[initialAlternative],
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)",
          },
        },
        content: {
          margin: 0,
        },
        expandIconWrapper: { color: colors.primary[initialAlternative] },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              border: "6px solid #fff",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.3,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            opacity: 1,
          },
        },
        sizeSmall: {
          height: 40,
        },
        sizeMedium: {
          width: 42,
          height: 26,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: fluidSpacing.s,
            paddingRight: fluidSpacing.xs,
            backgroundColor: colors.white,
          },
          "& *": {
            lineHeight: 0,
          },
          "& .MuiInputBase-adornedStart": {
            paddingLeft: fluidSpacing.s,
            backgroundColor: colors.white,
            input: {
              paddingLeft: 0,
            },
          },
          "& .MuiInputBase-adornedEnd": {
            paddingRight: fluidSpacing.xs,
            backgroundColor: colors.white,
            input: {
              paddingRight: 0,
            },
          },
          "& .MuiInputAdornment-root": {
            svg: {
              width: fluidSpacing.m,
              height: fluidSpacing.m,
            },
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            "& fieldset": {
              borderColor: colors.neutral[400],
              lineHeight: 0,
            },
            "&:hover fieldset": {
              borderColor: colors.neutral[500],
            },
            "&.Mui-focused fieldset": {
              border: "1px solid",
              borderColor: colors.neutral[900],
            },
            "&.Mui-error": {
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: colors.neutral[900],
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: colors.neutral[900],
              },
            },
            "&.Mui-disabled": {
              opacity: 0.8,
              fieldset: {
                borderColor: colors.neutral[400],
              },
              "&:hover fieldset": {
                borderColor: colors.neutral[400],
              },
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          position: "relative",
        },
        colorPrimary: {
          color: colors.primary[400],
        },
        circleDeterminate: {
          color: colors.neutral[200],
        },
        circleIndeterminate: {
          color: colors.primary[400],
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          display: "flex",
          flexWrap: "wrap",
          "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            borderRadius: 4,
            borderLeft: `1px solid ${neutral[300]}`,
          },
          "& .MuiToggleButtonGroup-grouped:not(:first-of-type):hover": {
            borderLeft: `1px solid ${neutral[500]}`,
          },
          "& .MuiToggleButtonGroup-grouped.Mui-selected:not(:first-of-type) ": {
            borderLeft: `2px solid ${info[500]}`,
          },
          "& .MuiToggleButtonGroup-grouped:first-of-type": {
            borderRadius: 4,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          borderColor: colors.neutral[300],
          backgroundColor: colors.white,
          color: neutral[500],
          textTransform: "none",
          ":hover": {
            border: "solid ",
            borderWidth: "1px",
            borderColor: `${colors.info[500]} !important`,
          },
          "&.Mui-selected": {
            border: "solid !important",
            borderWidth: "2px !important",
            backgroundColor: `${colors.primary.alternative1[50]} !important`,
            borderColor: `${colors.info[500]} !important`,
            color: neutral[500],
          },

          ".MuiToggleButtonGroup-vertical &:not(:first-of-type)": {
            borderTopColor: `${colors.neutral[400]} !important`,

            ":hover": {
              borderTopColor: `${colors.info[500]} !important`,
            },

            "&.Mui-selected": {
              borderTopColor: `${colors.info[500]} !important`,
            },
          },

          "&.Mui-disabled": {
            borderColor: colors.neutral[300],
            color: colors.neutral[300],
            backgroundColor: `${neutral[50]} !important`,

            "&.Mui-selected": {
              backgroundColor: `${colors.secondary.alternative3[100]} !important`,
            },
          },
        },
      },
    },
  },
};

const themeConfig = createTheme(themeOptions);
export default themeConfig;

declare module "@mui/material/styles" {
  interface Palette {
    complementary: Palette["primary"];
  }
  interface PaletteOptions {
    complementary: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    complementary: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    "tw-sm": true;
    "tw-md": true;
    "tw-lg": true;
    "tw-xl": true;
  }
}
