import { createSlice } from "@reduxjs/toolkit";
import { capitalizeWord, dateToISO, getAge } from "app/utils";

import { setBanks, setBrokers } from "./quotationSlice";

const initialState = {
  subscriptionId: null,
  insuredId: null,
  productId: null,
  productCode: null,
  trackingId: null,
  quotationId: null,
  plan: null,
  modality: null,
  termsAndConditions: false,
  waitingTime: false,
  confiRed: null,
  titular: {
    incompleteServiceData: false,
    isWrongGender: false,
    identificationType: null,
    identification: null,
    firstName: null,
    middleName: null,
    lastName: null,
    surname: null,
    maidenSurname: null,
    gender: null,
    birthDate: "cambiar a birthDateISO",
    birthDateISO: null,
    birthDateAlt: null,
    age: null,
    email: null,
    phone: null,
    maternity: null,
    nationality: null,
    address: null,
    preExisting: [],
    allPreExistingList: [],
    previousInsurance: false,
    detailPreExisints: false,
    preExistenceDataCorrect: false,
  },
};

export const setFromRequestIdentification =
  (payload) => (dispatch, getState) => {
    const name = `${capitalizeWord(payload.firstName)} ${capitalizeWord(
      payload.middleName
    )}`;
    const lastName = `${capitalizeWord(payload.surname)} ${capitalizeWord(
      payload.maidenSurname
    )}`;
    if (payload) {
      const titular = {
        ...getState().subscriptionInfo.titular,
        incompleteServiceData: payload.incompleteServiceData,
        isWrongGender: payload?.isWrongGender ?? false,
        identification: payload.identification,
        name,
        lastName,
        firstName: capitalizeWord(payload.firstName),
        middleName: capitalizeWord(payload.middleName),
        surname: capitalizeWord(payload.surname),
        maidenSurname: capitalizeWord(payload.maidenSurname),
        email: payload.email,
        phone: payload.mobilePhone || payload.phone,
        // nationality: payload.nationality,
      };
      dispatch(subscriptionSlice.actions.setTitular(titular));
    }
  };

export const setTitularFromManual =
  (payload: { maternity: string; gender: string; birthDate: Date }) =>
  (dispatch, getState) => {
    const titular = {
      ...getState().subscriptionInfo.titular,
      birthDateISO: dateToISO(payload.birthDate, false, "-"),
      birthDateAlt: dateToISO(payload.birthDate, true, "-"),
      gender: payload.gender,
      age: getAge(dateToISO(payload.birthDate, true, "/")),
      maternity: payload.maternity,
    };
    dispatch(subscriptionSlice.actions.setTitular(titular));
  };

export const setQuotationInfo = (payload: { banks; brokers }) => (dispatch) => {
  dispatch(setBanks(payload.banks));
  dispatch(setBrokers(payload.brokers));
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setProductCode: (state, action) => {
      state.productCode = action.payload;
    },
    setTrackingId: (state, action) => {
      state.trackingId = action.payload;
    },
    setQuotationId: (state, action) => {
      state.quotationId = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setModalityAndConfiRed: (state, action) => {
      state.modality = action.payload.modality;
      state.confiRed = action.payload.confiRed;
    },
    setTermsAndConditions: (state, action) => {
      state.termsAndConditions = action.payload;
    },
    setTitular: (state, action) => {
      state.titular = action.payload;
    },
    setTitularIdentificationType: (state, action) => {
      state.titular.identificationType = action.payload;
    },
    setTitularPassportData: (state, action) => {
      state.titular.identification = action.payload.passport;
      state.titular.nationality = action.payload.nationality;
      state.titular.incompleteServiceData =
        action.payload.incompleteServiceData;
      state.titular.isWrongGender = action.payload.isWrongGender;
      state.titular.firstName = "";
      state.titular.middleName = "";
      state.titular.lastName = "";
      state.titular.surname = "";
      state.titular.maidenSurname = "";
    },
    setTitularData: (state, action) => {
      state.titular = {
        ...state.titular,
        ...action.payload,
      };
    },
    setTitularIdentification: (state, action) => {
      state.titular = {
        ...initialState.titular,
        identification: action.payload,
      };
    },
    setTitularGender: (state, action) => {
      state.titular.gender = action.payload;
    },
    updateTitularContactData: (state, action) => {
      state.titular.previousInsurance = action.payload.previousInsurance;
      state.titular.detailPreExisints = action.payload.detailPreExisints;
    },
    addTitularPreExisting: (state, action) => {
      state.titular.preExisting.push(action.payload);
    },
    setPreExisting: (state, action) => {
      state.titular.preExisting = action.payload;
    },
    setPreExistingDataCorrect: (state, action) => {
      state.titular.preExistenceDataCorrect = action.payload;
    },
    setAllPreExistingList: (state, action) => {
      state.titular.allPreExistingList = action.payload;
    },
    removeTitularPreExisting: (state, action) => {
      state.titular.preExisting = state.titular.preExisting.filter(
        (item) => item.code !== action.payload
      );
    },
    removeAllTitularPreExisting: (state) => {
      state.titular.preExisting = [];
    },
    setInsuredId: (state, action) => {
      state.insuredId = action.payload;
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload;
    },
    setWaitingTime: (state, action) => {
      state.waitingTime = action.payload;
    },
  },
});

export const {
  setProductId,
  setProductCode,
  setTrackingId,
  setQuotationId,
  setPlan,
  setModalityAndConfiRed,
  addTitularPreExisting,
  setTitularData,
  setPreExisting,
  setPreExistingDataCorrect,
  setTitular,
  setTitularPassportData,
  setTitularIdentificationType,
  setTitularGender,
  updateTitularContactData,
  setAllPreExistingList,
  removeTitularPreExisting,
  removeAllTitularPreExisting,
  setTitularIdentification,
  setInsuredId,
  setSubscriptionId,
  setWaitingTime,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
