function MaternityIcon(props) {
  return (
    <svg
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <rect x="0.5" width="46" height="46" rx="23" fill="#E4F2FA" />
      <mask
        id="mask0_942_15486"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="31"
        height="30"
      >
        <rect x="8.5" y="8" width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_942_15486)">
        <path
          d="M28.4991 35.5C27.8116 35.5 27.2233 35.2554 26.7341 34.7662C26.2441 34.2762 25.9991 33.6875 25.9991 33C25.9991 32.3125 26.2441 31.7237 26.7341 31.2337C27.2233 30.7446 27.8116 30.5 28.4991 30.5C29.1866 30.5 29.7754 30.7446 30.2654 31.2337C30.7546 31.7237 30.9991 32.3125 30.9991 33C30.9991 33.6875 30.7546 34.2762 30.2654 34.7662C29.7754 35.2554 29.1866 35.5 28.4991 35.5ZM15.9991 35.5C15.3116 35.5 14.7233 35.2554 14.2341 34.7662C13.7441 34.2762 13.4991 33.6875 13.4991 33C13.4991 32.3125 13.7441 31.7237 14.2341 31.2337C14.7233 30.7446 15.3116 30.5 15.9991 30.5C16.6866 30.5 17.275 30.7446 17.7641 31.2337C18.2541 31.7237 18.4991 32.3125 18.4991 33C18.4991 33.6875 18.2541 34.2762 17.7641 34.7662C17.275 35.2554 16.6866 35.5 15.9991 35.5ZM20.4991 26.75H27.2491V18.8437L20.4991 26.75ZM17.7804 29.25C17.2387 29.25 16.8587 29.0104 16.6404 28.5312C16.4212 28.0521 16.4887 27.6042 16.8429 27.1875L28.7491 13.2188C29.1658 12.7396 29.6291 12.375 30.1391 12.125C30.65 11.875 31.2075 11.75 31.8116 11.75C32.9783 11.75 33.9679 12.1562 34.7804 12.9687C35.5929 13.7812 35.9991 14.7708 35.9991 15.9375V16.75H33.4991V15.9375C33.4991 15.4583 33.3379 15.0571 33.0154 14.7337C32.6921 14.4112 32.2908 14.25 31.8116 14.25C31.5825 14.25 31.3796 14.2917 31.2029 14.375C31.0254 14.4583 30.8637 14.5833 30.7179 14.75L29.7491 15.8437V26.75C29.7491 27.4375 29.5046 28.0262 29.0154 28.5162C28.5254 29.0054 27.9366 29.25 27.2491 29.25H17.7804ZM20.3429 16.3125L22.0616 14.3125C21.895 14.2917 21.7233 14.2758 21.5466 14.265C21.3691 14.255 21.1866 14.25 20.9991 14.25C20.5825 14.25 20.1816 14.2812 19.7966 14.3437C19.4108 14.4062 19.02 14.4896 18.6241 14.5937L20.3429 16.3125ZM20.4991 20L14.4054 13.9062C15.4054 13.1979 16.4575 12.6617 17.5616 12.2975C18.6658 11.9325 19.8116 11.75 20.9991 11.75C21.9366 11.75 22.8587 11.8646 23.7654 12.0938C24.6712 12.3229 25.5408 12.6667 26.3741 13.125L20.4991 20Z"
          fill="#0057D1"
        />
      </g>
    </svg>
  );
}

export default MaternityIcon;
