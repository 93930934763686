import { ReactNode } from "react";
import AccordionDetails, {
  AccordionDetailsProps,
} from "@mui/material/AccordionDetails";

interface EvaAccordionDetailsProps
  extends Omit<AccordionDetailsProps, "className"> {
  children: ReactNode | ReactNode[];
}

export default function EvaAccordionDetails({
  children,
  ...props
}: EvaAccordionDetailsProps) {
  return <AccordionDetails {...props}>{children}</AccordionDetails>;
}
