import { createSlice } from "@reduxjs/toolkit";

import { setProductCode, setProductId } from "./subscriptionSlice";

const initialState = {
  product: null,
  categories: null,
  plans: {
    documentUrl: null,
    list: [],
  },
  channelWay: null,
  periodicityPayments: null,
  networkCalled: false,
  confiredOptions: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setPlans: (state, action) => {
      state.plans.list = action.payload.plans;
      state.plans.documentUrl = action.payload.documentUrl;
    },
    setNetworkCalled(state, action) {
      state.networkCalled = action.payload;
    },
    setChannelWay: (state, action) => {
      state.channelWay = action.payload;
    },
    setPeriodicityPayments: (state, action) => {
      state = action.payload;
    },
    setConfiredOptions(state: typeof initialState, action) {
      state.confiredOptions = action.payload;
    },
  },
});
export const setProduct = (payload) => (dispatch) => {
  dispatch(productSlice.actions.setProduct(payload));
  dispatch(setProductId(payload.id));
  dispatch(setProductCode(payload.code));
};

export const {
  setCategories,
  setPlans,
  setChannelWay,
  setNetworkCalled,
  setConfiredOptions,
  setPeriodicityPayments,
} = productSlice.actions;

export default productSlice.reducer;
