function ConfiRedIcon(props) {
  return (
    <svg
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <rect x="0.5" width="46" height="46" rx="23" fill="#E4F2FA" />
      <mask
        id="mask0_942_15466"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="31"
        height="30"
      >
        <rect x="8.5" y="8" width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_942_15466)">
        <path
          d="M21.6241 29.25H25.3741V26.125H28.4991V22.375H25.3741V19.25H21.6241V22.375H18.4991V26.125H21.6241V29.25ZM13.4991 34.25V19.25L23.4991 11.75L33.4991 19.25V34.25H13.4991ZM15.9991 31.75H30.9991V20.5L23.4991 14.875L15.9991 20.5V31.75Z"
          fill="#0057D1"
        />
      </g>
    </svg>
  );
}

export default ConfiRedIcon;
