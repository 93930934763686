import { useSelector } from "react-redux";
import configuration from "app/data/configuration/configuration.json";
import protectedRoutes from "app/data/configuration/protected-routes.json";

export const CheckDataForRoute = (route) => {
  const allRules = protectedRoutes;

  // @ts-ignore
  const productInfoData = useSelector(({ productInfo }) => productInfo);
  // @ts-ignore
  const quotationInfoData = useSelector(({ quotationInfo }) => quotationInfo);

  const subscriptionInfoData = useSelector(
    // @ts-ignore
    ({ subscriptionInfo }) => subscriptionInfo
  );
  const dependentsInfoData = useSelector(
    // @ts-ignore
    ({ dependentsInfo }) => dependentsInfo
  );
  // @ts-ignore
  const payerInfoData = useSelector(({ payerInfo }) => payerInfo);

  const reduxData = {
    productInfo: productInfoData,
    quotationInfo: quotationInfoData,
    subscriptionInfo: subscriptionInfoData,
    dependentsInfo: dependentsInfoData,
    payerInfo: payerInfoData,
  };

  const indexRulValidation = allRules.findIndex(
    (item) => item.stepRoute === route
  );

  if (indexRulValidation >= 0 && configuration.security.useProtectedRoutes) {
    return ckeckRule(allRules[indexRulValidation].rules, reduxData);
  }

  return true;
};

/**
 *
 * @param rules Rules to validate
 * @param reduxData Info in redux to validate
 * @param validation validation status
 * @returns true if the validation rules are met, false if the rules are not met
 */
export const ckeckRule = (rules, reduxData, validation = true) => {
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in rules) {
    const rule = rules[key];
    if (validation) {
      if ("childrens" in rule) {
        validation =
          reduxData[rule.element] !== null &&
          ckeckRule(rule.childrens, reduxData[rule.element], validation);
      } else {
        const valInRedux = reduxData[rule.element];
        validation = valInRedux !== null;
      }
    } else {
      break;
    }
  }

  return validation;
};
